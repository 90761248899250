import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteReactionMutation,
  useGetReactionsQuery,
  usePostReactionMutation,
  usePutReactionMutation,
  useLazyIsReactionUniqueQuery,
} from '@/redux/api/system/reactionsApiSlice';

import { useTranslation } from 'react-i18next';
import { ReactionReq, ReactionRes } from './typings';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useReactionColumns from './useReactionColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { REACTION_KIND, ROUTES } from '@/shared/constants';
import useUniqueness from '@/hooks/useUniqueness';

export const ReactionsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ReactionRes>));

export default function ReactionsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetReactionsQuery();
  const [postReactionTrigger] = usePostReactionMutation();
  const [putReactionTrigger] = usePutReactionMutation();
  const [deleteReactionTrigger] = useDeleteReactionMutation();
  const [isReactionUnique] = useLazyIsReactionUniqueQuery();
  const validateUniqueness = useUniqueness<ReactionRes, 'name'>({
    isUniqueTrigger: isReactionUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<ReactionReq>({
    title: t(ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'kind',
        placeholder: t('kind'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(REACTION_KIND).map((kind) => ({ label: t(kind), value: kind })),
        },
      },
      {
        name: 'destination',
        placeholder: t('destination'),
        helperText: t('commaSeparatedValuesHelperText', { subject: t('destination') }) as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const reaction = data?.data?.items.find((reaction: any) => reaction.id === dialogId);
      return {
        ...reaction,
        ...(reaction?.destination ? { destination: (reaction.destination as string[]).join(', ') } : {}),
      };
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const submitData = {
        ...data,
        ...(data.destination ? { destination: (data.destination as unknown as string).split(',') } : {}),
      };

      if (isEditing) {
        await putReactionTrigger({
          id: dialogId,
          body: {
            ...data,
            ...(data.destination ? { destination: (data.destination as unknown as string).split(',') } : {}),
          },
        });
      } else {
        await postReactionTrigger(submitData);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: `${t('delete')} ${t('reaction')}`,
    message: (extra: any) => t('deleteMessage', { entity: t('reaction'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteReactionTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useReactionColumns(openDialog, confirm);

  return (
    <>
      <ReactionsTable
        title={t(ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('reaction')}
        onResetStateButtonClick={openDialog}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
