import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import ReleatedEntityLabel from '@/components/Shared/Chips/ReleatedEntityChip';
import TagIcon from '@mui/icons-material/Tag';
import { FleetAlarm } from '../typings';
import { SEVERITY } from '@/shared/constants';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import { DeviceAlarm } from '../../Devices/DevicesPanel/typings';

import { typeObject } from '@/shared/utils';
import TypographyWithTooltip from '@/components/Shared/Tooltip';

export default function useAlarmColumns({
  onDetailClick,
  excludeColumns,
  isFleetAlarm,
}: {
  onDetailClick: Function;
  excludeColumns?: string[];
  isFleetAlarm: boolean;
}): Array<Column<FleetAlarm> | Column<DeviceAlarm>> {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const columns_array: Array<Column<FleetAlarm> | Column<DeviceAlarm>> = [
    isFleetAlarm
      ? typeObject<Column<FleetAlarm>>({
          title: <TypographyWithTooltip anchorText={t('severity')} tooltipText={t('severityTooltip')} />,
          field: 'severity',
          exportTransformer: (alarm) => (alarm.severity ? t(alarm.severity) : t('unknown')),
          customExport: (alarm) => {
            return alarm.severity ? t(alarm.severity) : t('unknown');
          },
          render: (alarm) => {
            if (!alarm.severity) {
              return (
                <Chip
                  avatar={
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      <Box
                        sx={{
                          height: '80%',
                          bgcolor: theme.palette.grey[600],
                          width: '80%',
                          borderRadius: '50%',
                        }}
                      />
                    </Avatar>
                  }
                  label={t('unknown')}
                  size="small"
                  color="default"
                  variant="filled"
                />
              );
            }

            return (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={{
                        height: '80%',
                        bgcolor:
                          alarm.severity === SEVERITY.CRITICAL
                            ? theme.palette.warning.dark
                            : theme.palette.caution.dark,
                        width: '80%',
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                }
                label={t(alarm.severity)}
                size="small"
                variant="filled"
                color={alarm.severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
              />
            );
          },
        })
      : typeObject<Column<DeviceAlarm>>({
          title: t('severity'),
          field: 'severity',
          exportTransformer: (alarm) => (alarm.alarm.severity ? t(alarm.alarm.severity) : t('unknown')),
          customExport: (alarm) => {
            return alarm.alarm.severity ? t(alarm.alarm.severity) : t('unknown');
          },
          render: (alarm: DeviceAlarm) => {
            if (!alarm.alarm.severity) {
              return (
                <Chip
                  avatar={
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      <Box
                        sx={{
                          height: '80%',
                          bgcolor: theme.palette.grey[600],
                          width: '80%',
                          borderRadius: '50%',
                        }}
                      />
                    </Avatar>
                  }
                  label={t('unknown')}
                  size="small"
                  color="default"
                  variant="filled"
                />
              );
            }

            return (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={{
                        height: '80%',
                        bgcolor:
                          alarm.alarm.severity === SEVERITY.CRITICAL
                            ? theme.palette.warning.light
                            : theme.palette.caution.light,
                        width: '80%',
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                }
                label={t(alarm.alarm.severity)}
                size="small"
                color={alarm.alarm.severity === SEVERITY.CRITICAL ? 'warning' : 'caution'}
                variant="filled"
              />
            );
          },
        }),
    typeObject<Column<FleetAlarm>>({
      title: t('date'),
      field: 'time',
      exportTransformer: (alarm) => dayjs(alarm.time).format('L'),
      render: (alarm) => (
        <>
          <Typography>{dayjs(alarm.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(alarm.time).fromNow()}</Typography>
        </>
      ),
    }),
    isFleetAlarm
      ? typeObject<Column<FleetAlarm>>({
          title: t('name'),
          field: 'name',

          exportTransformer: (alarm) => alarm.name || alarm.id,
          render: (alarm) => {
            return (
              <>
                <Typography>{alarm.name}</Typography>
                {alarm.alarmId && <Chip icon={<TagIcon />} label={alarm.alarmId} size="small" />}
              </>
            );
          },
        })
      : typeObject<Column<DeviceAlarm>>({
          title: t('name'),
          field: 'name',
          exportTransformer: (alarm) => alarm.alarm.name || alarm.alarm.id,
          render: (alarm) => (
            <>
              <Typography>{alarm.alarm.name}</Typography>
              {alarm.alarm.id && <Chip icon={<TagIcon />} label={alarm.alarm.id} size="small" />}
            </>
          ),
        }),
    ...(isFleetAlarm
      ? [
          {
            title: t('device'),
            field: 'deviceId',
            exportTransformer: (alarm: FleetAlarm) => alarm.deviceName,
            render: (alarm: FleetAlarm) => (
              <ReleatedEntityLabel
                text={alarm.deviceName || (t('device') as string)}
                onEntityClick={() => navigate(`/fleet/devices/${alarm.deviceId}`)}
              />
            ),
          },
        ]
      : []),
    isFleetAlarm
      ? {
          title: <TypographyWithTooltip anchorText={t('status')} tooltipText={t('statusTooltip')} />,
          field: 'dismissed',
          exportTransformer: (alarm: FleetAlarm) => {
            const statusAlarm = alarm.dismissed ? 'resolved' : 'active';
            return statusAlarm;
          },
          render: (alarm: FleetAlarm) => (
            <>
              {alarm.dismissed && (
                <Chip
                  avatar={
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      <Box
                        sx={{
                          height: '80%',
                          bgcolor: theme.palette.success.light,
                          width: '80%',
                          borderRadius: '50%',
                        }}
                      />
                    </Avatar>
                  }
                  label={t('resolved')}
                  size="small"
                  color="success"
                  variant="outlined"
                />
              )}
              {!alarm.dismissed && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
              {alarm.dismissed && (
                <>
                  <br />
                  <Typography variant="caption">{dayjs(alarm.dismissedAt).fromNow()}</Typography>
                </>
              )}
            </>
          ),
        }
      : {
          title: t('status'),
          exportTransformer: (alarm: FleetAlarm) => {
            const statusAlarm = alarm.dismissed ? 'resolved' : 'active';
            return statusAlarm;
          },
          field: 'dismissed',
          render: () => <BlipChip label={t('active')} size="small" color="error" variant="outlined" />,
        },
    {
      title: t('actions'),
      hidden: excludeColumns?.includes('actions'),
      render: (alarm: FleetAlarm | DeviceAlarm) => (
        <Box display="flex" gap={1}>
          <Button variant="outlined" color="primary" onClick={() => onDetailClick(alarm.id)}>
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ];

  return columns_array;
}
