import { Column } from '@material-table/core';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConsumableRes } from './typings';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { getPath } from '@/shared/utils';

export default function useConsumablesColumns(
  onEditClick?: Function,
  onDeleteClick?: Function,
  itemsInDeviceIdentity?: ConsumableRes[]
): Array<Column<ConsumableRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns_array: Array<Column<ConsumableRes>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('type'),
      field: 'kind',
      export: false,
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (consumable) => dayjs(consumable.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (consumable) => dayjs(consumable.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '10%',
      render: (consumable) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          {!itemsInDeviceIdentity ? (
            <>
              <Button variant="outlined" color="primary" onClick={onEditClick && (() => onEditClick(consumable.id))}>
                {t('edit')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: consumable.id, name: consumable.name }))}
              >
                {t('delete')}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(getPath('ONETOOL_CHANNELS_CONSUMABLES'), {
                  state: {
                    searchText: consumable.name,
                    highlight: {
                      refField: 'id',
                      matchValue: consumable.id,
                    },
                  },
                })
              }
            >
              {t('view')}
            </Button>
          )}
        </Box>
      ),
      sorting: false,
    },
  ]);
}
