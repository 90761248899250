import { ApiResult } from '@typings';
import {
  DeviceIdentityReq,
  DeviceIdentityRes,
  DeviceIdentityWithRelatedRes,
} from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentitiesPanel/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/deviceIdentities`;

export const DeviceIdentitiesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['DeviceIdentities'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDeviceIdentities: builder.query<ApiResult<{ items: DeviceIdentityRes[]; appEnv: string }>, void>({
        query: () => URL_PREFIX + '/list',
        providesTags: ['DeviceIdentities'],
      }),
      getDeviceIdentity: builder.query<ApiResult<DeviceIdentityRes>, string>({
        query: (id: string) => ({
          url: URL_PREFIX,
          params: { id },
        }),
        providesTags: ['DeviceIdentities'],
      }),
      isDeviceIdentityUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
        query: (params: CommonIsEntityUniqueBase) => ({
          url: `${URL_PREFIX}/unique`,
          params,
        }),
      }),
      getDeviceIdentityWithRelated: builder.query<ApiResult<DeviceIdentityWithRelatedRes>, string>({
        query: (id: string) => ({
          url: URL_PREFIX + '/related',
          params: { id },
        }),
        providesTags: ['DeviceIdentities'],
      }),
      postDeviceIdentity: builder.mutation<ApiResult<DeviceIdentityRes>, DeviceIdentityReq>({
        query: (body: DeviceIdentityReq) => ({
          url: URL_PREFIX,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: ['DeviceIdentities'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'POST',
            subject: arg.name,
            entity: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment,
          });
        },
      }),
      putDeviceIdentity: builder.mutation<ApiResult<DeviceIdentityRes>, { id: string; body: DeviceIdentityReq }>({
        query: ({ id, body }: { id: string; body: DeviceIdentityReq }) => ({
          url: URL_PREFIX,
          method: 'PUT',
          body: body,
          params: { id },
        }),
        invalidatesTags: ['DeviceIdentities'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'PUT',
            subject: arg.body.name,
            entity: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment,
          });
        },
      }),
      patchDeviceIdentity: builder.mutation<
        ApiResult<DeviceIdentityRes>,
        { id: string; body: Partial<DeviceIdentityReq>; name: string }
      >({
        query: ({ id, body }) => ({
          url: URL_PREFIX,
          method: 'PATCH',
          body: body,
          params: { id },
        }),
        invalidatesTags: ['DeviceIdentities'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'PATCH',
            subject: arg.name,
            entity: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment,
          });
        },
      }),
      deleteDeviceIdentity: builder.mutation<ApiResult<null>, { id: string; name: string }>({
        query: ({ id }: { id: string; name: string }) => ({
          url: URL_PREFIX,
          method: 'DELETE',
          params: { id },
        }),
        invalidatesTags: ['DeviceIdentities'],
        onQueryStarted: (arg, api) => {
          dispatchPendingToOperationsSlice(api, {
            url_prefix: URL_PREFIX,
            method: 'DELETE',
            subject: arg.name,
            entity: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment,
          });
        },
      }),
    }),
  });

export const {
  useGetDeviceIdentityWithRelatedQuery,
  useGetDeviceIdentityQuery,
  useGetDeviceIdentitiesQuery,
  useLazyGetDeviceIdentitiesQuery,
  useLazyGetDeviceIdentityQuery,
  useLazyIsDeviceIdentityUniqueQuery,
  usePostDeviceIdentityMutation,
  usePutDeviceIdentityMutation,
  usePatchDeviceIdentityMutation,
  useDeleteDeviceIdentityMutation,
} = DeviceIdentitiesApiSlice;
