import useAlarmColumns from './useAlarmsColumns';
import { AlarmReq, AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import {
  useDeleteAlarmMutation,
  useGetAlarmsQuery,
  useLazyGetAlarmQuery,
  usePutAlarmMutation,
  usePostAlarmMutation,
  useLazyIsAlarmUniqueQuery,
} from '@/redux/api/system/alarmsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FieldsUndefined } from '@/shared/typings';
import { isValidJSON } from '@/shared/utils';
import { ROLES, ROUTES, SENDING_STYLE, SEVERITY } from '@/shared/constants';
import useUniqueness from '@/hooks/useUniqueness';

export const AlarmsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<AlarmRes>));

export default function AlarmPanel() {
  const { t } = useTranslation();

  // Queries and mutations
  const { data, isLoading, isError } = useGetAlarmsQuery();
  const [getAlarm] = useLazyGetAlarmQuery();
  const [postAlarmTrigger] = usePostAlarmMutation();
  const [putAlarmTrigger] = usePutAlarmMutation();
  const [deleteAlarmTrigger] = useDeleteAlarmMutation();
  const [isAlarmUnique] = useLazyIsAlarmUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<AlarmRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isAlarmUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<AlarmReq>({
    title: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SENDING_STYLE).map((sendingStyle) => ({
            label: t(sendingStyle),
            value: sendingStyle,
          })),
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        type: 'autocomplete',
        name: 'severity',
        placeholder: t('severity'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SEVERITY).map((severity) => ({ label: t(severity), value: severity })),
        },
      },
      {
        type: 'checkbox',
        name: 'parameters',
        initialValue: false,
        placeholder: t('parameters'),
        mutations: (showFields, hideFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => {
                  const { parameters } = getValues();
                  parameters && showFields('payloadExample');
                  !parameters && hideFields('payloadExample');
                },
              },
            },
          },
        }),
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: true,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (formValues.parameters) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    mutations: (showFields, hideFields) => ({
      onMount: {
        impacts: {
          form: {
            handler: ({ getValues }) => {
              const { parameters } = getValues();
              parameters && showFields('payloadExample');
              !parameters && hideFields('payloadExample');
            },
          },
        },
      },
    }),
    getEditData: async (id: string | boolean) => {
      const res = await getAlarm(id as string);
      const alarm: any = { ...res.data?.data };
      return alarm;
    },
    onSubmit: async (id: string | boolean, data: any) => {
      const isEditing = typeof id === 'string';
      if (!data.parameters) {
        data.parameters = false;
      }
      if (!data.parameters && data.payloadExample) {
        delete data.payloadExample;
      }

      if (isEditing) {
        await putAlarmTrigger({ id, body: data });
      } else {
        await postAlarmTrigger(data);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
    message: (extra: any) =>
      t('deleteMessage', {
        entity: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
        name: extra.name,
        interpolation: { escapeValue: false },
      }) as string,
    onConfirm: (extra: any) => deleteAlarmTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useAlarmColumns(openDialog, confirm);

  return (
    <>
      <AlarmsTable
        title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('alarm')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
