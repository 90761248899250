import { useMemo } from 'react';
import { Device } from '../components/Fleet/Devices/DevicesPanel/typings';

type BrandOption = { label: string; value: string };

export const useBrandsOptions = (devices: Device[]) => {
  const uniqueOptions = useMemo(
    () =>
      devices?.reduce<Array<BrandOption>>((options, device) => {
        if (device.brandId && options.findIndex((o) => o.value === device.brandId) === -1) {
          options.push({
            label: device.brandName || device.brandId,
            value: device.brandId,
          });
        }
        return options;
      }, []),
    [devices]
  );
  return uniqueOptions || [];
};
