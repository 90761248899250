import { Column } from '@material-table/core';
import { Box, Button, Chip, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import TagIcon from '@mui/icons-material/Tag';
import { Device } from '../Devices/DevicesPanel/typings';
import { getColorsFromPercentage } from '@/shared/utils';

export const useConsumablesDeviceTableColumns = (selectedTab: string): Array<Column<Device>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMemo(
    (): Array<Column<Device>> => [
      {
        title: t('modelNameId'),
        cellStyle: { textAlign: 'left' },
        exportTransformer: (device) => `${device.model}\n${device.serialNumber}`,
        field: 'serialNumber',
        render: (device) => (
          <Box>
            <Typography variant="subtitle1">{device.name}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Chip icon={<TagIcon />} label={device.model} size="small" />
              <Typography variant="caption">{device.serialNumber}</Typography>
            </Box>
          </Box>
        ),
      },
      /*
       * Removed until further notice.
       * {
       *   title: t('location'),
       *   field: 'location',
       *   exportTransformer: (device) => `${device.region?.code} - ${device.region?.name}`,
       *   render: (device) => (
       *     <Typography variant="subtitle1">
       *       {device.region?.code && device.region?.name ? `${device.region.code} - ${device.region.name}` : 'N/A'}
       *     </Typography>
       *   ),
       *   sorting: false,
       * },
       */
      {
        title: t('levels'),
        field: 'levels',
        exportTransformer: (data) =>
          data.consumables
            .filter((consumable: any) => consumable.consumable.kind === selectedTab)
            .map((consumable: any) => {
              if (!consumable.subset?.id) {
                return null;
              }
              const normalise = (value: number, min: number, max: number) =>
                Math.round(((value - min) * 100) / (max - min));
              const fillPercentage = normalise(
                consumable.current,
                consumable.subset?.rangeExhaust || 0,
                consumable.subset?.rangeFullCapacity
              );

              return consumable.subset?.id + ' ' + fillPercentage + '%\n';
            })
            .toString()
            .replaceAll(',', ''),
        render: (data) => (
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={1}>
            {data.consumables.map((consumable: any, index: number) => {
              const normalise = (value: number, min: number, max: number) =>
                Math.round(((value - min) * 100) / (max - min));
              const fillPercentage = normalise(
                consumable.current,
                consumable.subset?.rangeExhaust || 0,
                consumable.subset?.rangeFullCapacity
              );

              const bgColor = getColorsFromPercentage(fillPercentage);
              const transparentBgColor = alpha(bgColor, 0.2);

              return (
                <>
                  {consumable.consumable.kind === selectedTab && (
                    <Box
                      key={`consumableSubset-${index + '-' + data.serialNumber}`}
                      sx={{ borderRadius: 1, position: 'relative', width: '100%' }}
                      bgcolor={transparentBgColor}
                    >
                      <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="caption" component="span">
                          {consumable.subset?.name ? consumable.subset?.name : consumable.subset?.id || 'Empty slot'}
                        </Typography>
                        {consumable.subset?.id && <Typography component="span">{fillPercentage}%</Typography>}
                      </Box>
                      {consumable.subset?.id && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'absolute',
                            overflow: 'hidden',
                            height: '100%',
                            top: 0,
                            left: 0,
                            width: '100%',
                            backgroundColor: bgColor,
                            clipPath: `xywh(0 0 ${fillPercentage}% 100%)`,
                            padding: 1,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: (theme) => theme.palette.getContrastText(bgColor) }}
                            component="span"
                          >
                            {consumable.subset?.name ?? consumable.subset?.id}
                          </Typography>
                          <Typography
                            sx={{ color: (theme) => theme.palette.getContrastText(bgColor) }}
                            component="span"
                          >
                            {fillPercentage}%
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              );
            })}
          </Box>
        ),
        sorting: false,
      },
      {
        title: t('actions'),
        field: 'actions',
        width: '5%',
        sorting: false,
        export: false,
        render: (device) => (
          <Button
            key={`buttonDevicesTable${device.serialNumber}`}
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/fleet/devices/${device.serialNumber}#consumables`)}
          >
            {t('view')}
          </Button>
        ),
      },
    ],
    [navigate, t, selectedTab]
  );
};
