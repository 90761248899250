import ErrorsTable from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsTable';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import { FleetError } from '@/components/Fleet/AlarmsAndErrors/typings';

export default function Errors() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }

  return (
    <Box>
      <ErrorsTable
        items={(context.data?.errors as FleetError[]) || []}
        isFleet={false}
        excludeColumns={['deviceName']}
      />
    </Box>
  );
}
