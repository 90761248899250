/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-redeclare */
import { Direction } from '@/components/Shared/Tables/typings';
import { DeviceID } from '@culligan-iot/domain/culligan/one/device';
import { Schema as S } from '@effect/schema';
import { Severity, SeverityT, Status, StatusT } from '@typings';
import { AlarmsTableFields } from './Alarms/useAlarmsFilters';
import { ErrorsTableFields } from './Errors/useErrorsFilters';
import { FleetDevices } from '../Devices/DevicesPanel/typings';

export type FilterAlarmRequest = {
  start: number;
  end: number;
  severity?: SeverityT;
  status?: StatusT;
  customer?: string;
  model?: string;
  brand?: string;
  page: number;
  size: number;
  orderBy?: AlarmsTableFields;
  direction?: Direction;
};

export type FleetAlarm = typeof FleetAlarm.Type;
export const FleetAlarm = S.Struct({
  status: Status,
  time: S.String.pipe(S.filter((s) => !s.includes(' '))),
  deviceId: DeviceID,
  id: S.String,
  dismissed: S.Boolean,
  dismissedAt: S.NullOr(S.DateFromString),
  name: S.String,
  createdAt: S.String,
  deviceName: S.String,
  alarmId: S.String,
  description: S.String,
  severity: Severity,
});

export type FilterErrorRequest = {
  start: number;
  end: number;
  status?: StatusT;
  customer?: string;
  brand?: string;
  page?: number;
  size?: number;
  model?: FleetDevices.Device['model'];
  orderBy?: ErrorsTableFields;
  direction?: Direction;
};

export type FleetError = typeof FleetError.Type;
export const FleetError = S.Struct({
  time: S.Number,
  id: S.String,
  deviceId: S.String,
  deviceName: S.String,
  dismissed: S.Boolean,
  dismissedAt: S.NullOr(S.DateFromString),
  name: S.String,
  createdAt: S.String,
});
