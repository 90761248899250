import { Avatar, Chip, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import DialogContentLabel from './DialogContentLabel';
import { FleetAlarm, FleetError } from '@/components/Fleet/AlarmsAndErrors/typings';
import { SEVERITY } from '@/shared/constants';
import BlipChip from '../Chips/BlipChip';
import ReleatedEntityLabel from '../Chips/ReleatedEntityChip';
import { useNavigate } from 'react-router';
import TagIcon from '@mui/icons-material/Tag';
import { DeviceAlarm } from '@/components/Fleet/Devices/DevicesPanel/typings';
import TypographyWithTooltip from '../Tooltip';

dayjs.extend(relativeTime);

const IssueDetails = ({ data, isFleet }: { data: FleetAlarm | DeviceAlarm | FleetError; isFleet: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      {isFleet && (
        <DialogContent
          sx={(theme) => ({ p: '2rem', background: theme.palette.background.grayShades[0], marginBottom: '1.5rem' })}
        >
          <Box>
            <Typography variant="overline">Device:</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="baseline">
              {(data as FleetAlarm).deviceName && (
                <DialogContentText textAlign="left" variant="h6">
                  {(data as FleetAlarm).deviceName}
                </DialogContentText>
              )}
              <ReleatedEntityLabel
                text="Open"
                onEntityClick={() => navigate(`/fleet/devices/${(data as FleetAlarm).deviceId}`)}
                size="small"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '0.2rem' }}>
            <DialogContentLabel variant="caption">{t('id')}:</DialogContentLabel>
            <DialogContentText variant="caption">{(data as FleetAlarm).deviceId}</DialogContentText>
          </Box>
        </DialogContent>
      )}

      <DialogContent sx={{ p: '2rem', py: isFleet ? '0' : '2rem', pb: '1rem' }}>
        <Box display="flex" gap={1}>
          <Box>
            {isFleet && (data as FleetAlarm).dismissed && (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={{
                        height: '80%',
                        bgcolor: theme.palette.success.light,
                        width: '80%',
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                }
                label={t('resolved')}
                size="small"
                color="success"
                variant="outlined"
              />
            )}
            {isFleet && !(data as FleetAlarm).dismissed && (
              <BlipChip label={t('active')} size="small" color="error" variant="outlined" />
            )}
            {isFleet && (data as FleetAlarm).dismissed && (
              <>
                <br />
                <Typography variant="caption">{dayjs((data as FleetAlarm).dismissedAt).fromNow()}</Typography>
              </>
            )}
          </Box>
          <Box>
            {!(data as FleetAlarm)?.severity && !(data as DeviceAlarm)?.alarm?.severity && (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={{
                        height: '80%',
                        bgcolor: theme.palette.grey[600],
                        width: '80%',
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                }
                label={t('unknown')}
                size="small"
                color="default"
                variant="filled"
              />
            )}
            {((data as FleetAlarm).severity || (data as DeviceAlarm)?.alarm?.severity) && (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={{
                        height: '80%',
                        bgcolor:
                          ((data as FleetAlarm).severity || (data as DeviceAlarm).alarm?.severity) === SEVERITY.CRITICAL
                            ? theme.palette.error.light
                            : theme.palette.warning.light,
                        width: '80%',
                        borderRadius: '50%',
                      }}
                    />
                  </Avatar>
                }
                label={t(((data as FleetAlarm).severity || (data as DeviceAlarm).alarm?.severity) as string)}
                size="small"
                color={
                  ((data as FleetAlarm).severity || (data as DeviceAlarm).alarm?.severity) === SEVERITY.CRITICAL
                    ? 'error'
                    : 'warning'
                }
                variant="filled"
              />
            )}
          </Box>
          <Box>
            {((data as FleetAlarm).alarmId || (data as DeviceAlarm).alarm?.id) && (
              <Chip
                icon={<TagIcon />}
                label={(data as FleetAlarm).alarmId || (data as DeviceAlarm).alarm?.id}
                size="small"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogContent sx={{ p: 0, px: '2rem', pb: '1rem' }}>
        <DialogContentLabel>{t('name')}:</DialogContentLabel>
        <DialogContentText textAlign="left" variant="body2">
          {(data as FleetAlarm).name || (data as DeviceAlarm).alarm?.name}
        </DialogContentText>
      </DialogContent>
      <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
        <Box display="flex" justifyContent="space-between" gap={'1rem'}>
          <Box>
            <TypographyWithTooltip anchorText={`${t('reportingDate')}: `} tooltipText={t('reportingDateTooltip')} />

            <Box display="flex" gap={'1rem'} alignItems="baseline">
              <DialogContentText>{dayjs(data.time).format('YYYY/MM/DD')}</DialogContentText>
              <Box>
                <DialogContentText variant="body2" fontStyle="italic">
                  {dayjs(data.time).fromNow()}
                </DialogContentText>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      {((data as FleetAlarm).description || (data as DeviceAlarm).alarm?.description) && (
        <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
          <DialogContentLabel>{t('description')}:</DialogContentLabel>
          <Box display="flex" gap={'1rem'} alignItems="baseline">
            <DialogContentText>
              {(data as FleetAlarm).description || (data as DeviceAlarm).alarm?.description}
            </DialogContentText>
          </Box>
        </DialogContent>
      )}
      <DialogContent sx={{ p: '2rem', py: '0', pb: '1rem' }}>
        <DialogContentLabel>{t('id')}:</DialogContentLabel>
        <DialogContentText textAlign="left" variant="caption">
          {data.id}
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default IssueDetails;
