import { useGetDeviceIdentitiesQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import { useDeviceIdentityColumns } from './useDeviceIdentityColumns';
import { DeviceIdentityRes } from './typings';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import useDeviceIdentityDialogs from './useDeviceIdentityDialogs';
import { ROUTES } from '@/shared/constants';
import React, { useDeferredValue } from 'react';

export const DeviceIdentitiesTable = React.memo(
  withErrorLoadingManagement(withResetNavigationState(GenericExportTable<DeviceIdentityRes>))
);

function DeviceIdentitiesPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetDeviceIdentitiesQuery(undefined, { refetchOnMountOrArgChange: false });

  const { AddEditDialog, openDialog, ConfirmDialog, confirm } = useDeviceIdentityDialogs();

  const columns = useDeviceIdentityColumns(openDialog, confirm, data?.data?.appEnv);

  const Table = useDeferredValue(() => (
    <DeviceIdentitiesTable
      title={t(ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={!isLoading}
      resetStateButtonLabel={t('add') + ' ' + t('deviceIdentity')}
      onResetStateButtonClick={openDialog}
    />
  ));

  return (
    <>
      <Table />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}

export default React.memo(DeviceIdentitiesPanel);
