import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { reduceDevicesWithConsumableOfKind, useDynamicConsumableColumns } from '.';
import { CONSUMABLE_KIND } from '@/shared/constants';
import { ConsumableContext } from '@/components/Fleet/Consumables/ConsumableContext';
import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';
import ConsumablesPanel from '@/components/Fleet/Consumables/ConsumablesPanel';

export default function Chemicals() {
  const { t } = useTranslation();
  const columns = useDynamicConsumableColumns(CONSUMABLE_KIND.CHEMICALS);
  const context = useContext(ConsumableContext);

  if (!context) {
    return <></>;
  }

  const { filteredData, isError, isLoading, handleSelection, selectedRowsID } = context;

  const reduceFx = reduceDevicesWithConsumableOfKind(CONSUMABLE_KIND.CHEMICALS);
  const devices = filteredData.reduce<Device[]>(reduceFx, []);

  return (
    <ConsumablesPanel
      data={devices}
      title={t(CONSUMABLE_KIND.ENHANCEMENTS)}
      columns={columns}
      isError={isError}
      isLoading={isLoading}
      exportData={false}
      handleSelection={handleSelection}
      selectedRowsID={selectedRowsID}
    />
  );
}
