import { alpha, Box, Button, Chip, CircularProgress, keyframes, Paper, Theme, Typography } from '@mui/material';
import { ACTION_STATUS } from './constants';
import { OccupiedSlotProps } from './typings';
import LoadingFeedback from './LoadingFeedback';
import ErrorFeedback from './ErrorFeedback';
import SuccessFeedback from './SuccessFeedback';
import AreaContainer, { AreaBody, AreaHeader, AreaRow } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { useTranslation } from 'react-i18next';
import {
  CropFree,
  InvertColorsOffOutlined,
  InvertColorsOutlined,
  LoopOutlined,
  Repeat,
  Tag,
} from '@mui/icons-material';
import Slot from './Slot';
import dayjs from 'dayjs';
import { yellow } from '@mui/material/colors';
import { DeviceConsumable } from '../../DevicesPanel/typings';
import SlotFooter from './SlotFooter';

const boxShadowAnimation = (theme: Theme) => keyframes`
0% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
50% {
  box-shadow: 0px 0px 10px ${alpha(theme.palette.error.light, 0.5)};
}
100% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
`;

const shakeAnimation = () => keyframes`
0% { transform: translateX(0) }
12.5% { transform: translateX(3px) }
25% { transform: translateX(-3px) }
37.5% { transform: translateX(3px) }
50% { transform: translateX(0) }
100% { transform: translateX(0) }
`;

const round = (val: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(val * multiplier) / multiplier;
};

export const numberValueCircularBar = (percentageFill: number, consumable: DeviceConsumable) => {
  const subsetData = consumable.subset;

  if (percentageFill === 100) {
    return subsetData?.rangeFullCapacity;
  } else if (percentageFill === 0) {
    return subsetData?.rangeExhaust;
  } else {
    if (percentageFill < 10) {
      return round(consumable.current, 3);
    }
    return round(consumable.current, 2);
  }
};

export default function OccupiedSlot({
  index,
  actionStatus,
  dismissErrorAnimation,
  setDismissErrorAnimation,
  performedAction,
  slot,
  handleClickSetMax,
  handleClickSetMin,
  handleClickEdit,
  handleClickRemove,
  isDeviceConnected,
}: OccupiedSlotProps) {
  const { t } = useTranslation();

  const today = dayjs();
  const end = dayjs(slot.expiresAt);
  const start = slot.subset?.lifespan ? end.subtract(slot.subset?.lifespan || 0, 'day') : dayjs(slot.installedAt);
  const differenceInDays = today.diff(start, 'day');
  const isExpired = end.isBefore(today);
  const lifespan = slot.subset?.lifespan || end.diff(start, 'day');
  const expirationPercentage = (100 * (differenceInDays || 1)) / (lifespan || 1);
  const safeExpirationPercentage = Math.max(0, Math.min(100, expirationPercentage));
  const expirationDate = dayjs(slot.expiresAt).format('L');
  const calcDot = `${safeExpirationPercentage}%`;

  const normalise = (value: number, min: number, max: number) => Math.round(((value - min) * 100) / (max - min || 1));
  const percentageFill = normalise(
    slot.current || 0,
    slot.subset?.rangeExhaust ?? 0,
    slot.subset?.rangeFullCapacity ?? 100
  );

  return (
    <Paper
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.palette.background.grayShades[0],
        display: 'grid',
        gridTemplate: '1fr auto / 1fr',
        maxWidth: '600px',
        width: '100%',
        borderRadius: 1,
        zIndex: 1,
        outline: `0px solid ${alpha(theme.palette.error.light, 0.2)}`,
        ...(actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation
          ? {
              animation: `${boxShadowAnimation(theme)} 3000ms infinite alternate ${
                theme.transitions.easing.easeInOut
              }, ${shakeAnimation()} 1s infinite`,
            }
          : {}),
      })}
      elevation={1}
      onMouseOver={() =>
        actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation && setDismissErrorAnimation(true)
      }
    >
      <LoadingFeedback loading={actionStatus === ACTION_STATUS.Loading} />
      <ErrorFeedback hasErrors={actionStatus === ACTION_STATUS.Error} action={performedAction} />
      <SuccessFeedback success={actionStatus === ACTION_STATUS.Success} />
      <Paper
        elevation={0}
        variant="outlined"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          height: '100%',
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 2,
          borderRadius: 1,
          width: '100%',
        })}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 1, height: '100%' }}>
          <AreaRow sx={{ display: 'grid', gridTemplateRows: '1.5fr 1fr', gap: 1 }}>
            <AreaContainer>
              <AreaHeader>
                <Subtitle>{t('subset')}</Subtitle>
                {slot.subset?.id && (
                  <Chip
                    icon={<Tag />}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.grayShades[0],
                    })}
                    label={slot.subset.id}
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                )}
              </AreaHeader>
              <AreaBody>
                {slot.subset?.name && <Title>{slot.subset.name}</Title>}
                {slot.subset?.description && (
                  <Typography variant="body2" color="text.primary">
                    {slot.subset.description}
                  </Typography>
                )}

                {slot?.batch && (
                  <>
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                      {t('batch')}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {slot.batch}
                    </Typography>
                  </>
                )}
              </AreaBody>
            </AreaContainer>
            <Slot
              sx={(theme) => ({
                position: 'relative',
                overflow: 'hidden',
                '&:before': {
                  content: differenceInDays >= 0 ? '""' : 'none',
                  backgroundColor: theme.palette.background.grayShades[1],
                  width: '16px',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
                '&:after': {
                  content: isExpired ? '""' : 'none',
                  backgroundColor: theme.palette.background.grayShades[1],
                  width: '16px',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                },
                '& > *:not([data-target="consumable-progress"])': {
                  zIndex: 1,
                },
              })}
            >
              <Box
                data-target="consumable-progress"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: '16px',
                  height: '100%',
                  width: 'calc(100% - 32px)',
                }}
              >
                <Box
                  sx={(theme) => ({
                    position: 'relative',
                    width: safeExpirationPercentage + '%',
                    height: '100%',
                    backgroundColor: theme.palette.background.grayShades[1],
                    transition: 'all 300ms ease-in-out',
                  })}
                ></Box>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                {!isExpired ? t('expiresAt') : t('expiredAt')}
              </Typography>
              <Typography variant="h5" color="text.primary" fontWeight={800}>
                {expirationDate}
              </Typography>
              <Typography variant="body2" color="text.primary">
                {!isExpired ? `${t('willExpire')} ${dayjs().to(end)}` : `${t('expired')} ${end.from(dayjs())}`}
              </Typography>
              {(slot.subset?.lifespan || slot.installedAt) && (
                <Box
                  sx={(theme) => ({
                    background: `linear-gradient(90deg, ${theme.palette.success.light} 0%, ${yellow[500]} 51.5%,
                    ${theme.palette.warning.light} 75.5%, ${theme.palette.error.light} 100%)`,
                    height: '10px',
                    borderRadius: 3,
                    marginTop: 1,
                    position: 'relative',
                    '&:after': {
                      content: '""',
                      backgroundColor: theme.palette.background.default,
                      width: '21px',
                      height: '21px',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                      left: `clamp(0%,${calcDot},100%)`,
                      borderRadius: '100%',
                      border: `1px solid hsl(${100 - safeExpirationPercentage} 61% 41% / 1)`,
                      transition: 'all 300ms ease-in-out',
                    },
                  })}
                ></Box>
              )}
              {lifespan ? (
                <Chip
                  icon={<LoopOutlined style={{ fontSize: '12px' }} />}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.grayShades[1],
                    position: 'absolute',
                    right: '16px',
                  })}
                  label={`${t('lifespan')} ${lifespan}  ${t('days')}`}
                  size="small"
                  color="default"
                  variant="outlined"
                />
              ) : (
                <></>
              )}
            </Slot>
          </AreaRow>
          <Slot sx={{ width: '100%', padding: 0 }}>
            <AreaContainer>
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                  {t('fillLevel')}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginY: 1,
                  marginX: 'auto',
                }}
              >
                <CircularProgress
                  variant="determinate"
                  size={'100%'}
                  value={100}
                  thickness={5}
                  sx={{ position: 'absolute', color: `hsl(${percentageFill} 100% 65% / 0.25)` }}
                />
                <CircularProgress
                  variant="determinate"
                  size={'100%'}
                  thickness={5}
                  value={percentageFill}
                  sx={{
                    color: `hsl(${percentageFill} 61% 41% / 1)`,
                    strokeLinecap: 'round',
                  }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    color={`hsl(${percentageFill} 61% 41% / 1)`}
                    fontWeight={800}
                    fontSize={35}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="center"
                  >
                    {percentageFill}
                    <span style={{ fontSize: '20px' }}>%</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="#bbb"
                    fontWeight={800}
                    fontSize={12}
                    lineHeight={1}
                  >
                    {numberValueCircularBar(percentageFill, slot)} of {slot.subset?.rangeFullCapacity}
                  </Typography>
                </Box>
              </Box>
            </AreaContainer>
            <AreaContainer>
              <Box>
                <Button
                  onClick={() => slot.subset?.id && handleClickSetMax(slot.subset.id)}
                  startIcon={<InvertColorsOutlined />}
                  variant="outlined"
                  disabled={!isDeviceConnected || percentageFill === 100}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                    borderRadius: 1,
                    border: '2px solid rgb(39 93 163)',
                    marginBottom: 1,
                    paddingX: 1,
                    paddingY: 0.5,
                    '&:hover': { border: '2px solid' },
                  }}
                >
                  {t('replenish')}
                </Button>
                <Button
                  onClick={() => handleClickSetMin()}
                  startIcon={<InvertColorsOffOutlined />}
                  variant="outlined"
                  disabled={!isDeviceConnected || percentageFill === 0}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                    borderRadius: 1,
                    border: '2px solid rgb(39 93 163)',
                    paddingX: 1,
                    paddingY: 0.5,
                    '&:hover': { border: '2px solid' },
                  }}
                >
                  {t('disable')}
                </Button>
              </Box>
            </AreaContainer>
          </Slot>
        </Box>
      </Paper>
      <SlotFooter
        actions={[
          {
            icon: <CropFree sx={{ mr: '0.2rem' }} />,
            label: t('change'),
            onClick: handleClickEdit,
            disabled: !isDeviceConnected,
          },
          {
            icon: <Repeat sx={{ mr: '0.2rem' }} />,
            label: t('remove'),
            onClick: handleClickRemove,
            color: 'error',
            disabled: !isDeviceConnected,
          },
        ]}
        index={index}
        isDeviceConnected={isDeviceConnected}
        slot={slot}
      />
    </Paper>
  );
}
