import { BoxProps } from '@mui/material';
import { useCallback, useState } from 'react';
import { StoreFilterOption, StoreFiltersProps } from './typings';
import FilterBar from './FilterBar';

const StoreFilters = ({
  filterConfigs,
  onFiltersApplied,
  onFiltersCleared,
  flexDirection = 'row',
  ...props
}: BoxProps & StoreFiltersProps) => {
  const [appliedFiltersMap, setAppliedFiltersMap] = useState<Map<string, string | null>>(new Map());

  const handleOnFilterChange = useCallback(
    (filterId: string, selectedValue: null | StoreFilterOption) => {
      if (selectedValue == null) {
        appliedFiltersMap.delete(filterId);
      } else if (selectedValue) {
        appliedFiltersMap.set(filterId, selectedValue.optionId);
      }

      setAppliedFiltersMap((prev) => {
        if (selectedValue == null) {
          prev.delete(filterId);
        } else if (selectedValue) {
          prev.set(filterId, selectedValue.optionId);
        }
        return prev;
      });
    },
    [appliedFiltersMap]
  );

  const handleClearAll = useCallback(() => {
    const filtersCleared = new Map();
    onFiltersApplied(filtersCleared);
    setAppliedFiltersMap(filtersCleared);

    if (onFiltersCleared) {
      onFiltersCleared();
    }
  }, [onFiltersApplied, onFiltersCleared]);

  const handleApplyFilters = useCallback(() => {
    onFiltersApplied(appliedFiltersMap);
  }, [appliedFiltersMap, onFiltersApplied]);

  return (
    <FilterBar<any>
      kind="store"
      filterConfigs={filterConfigs}
      onFilterChange={handleOnFilterChange}
      handleApplyFilters={handleApplyFilters}
      handleClearAll={handleClearAll}
      {...props}
    />
  );
};

export default StoreFilters;
