import { APP_ROUTE_ID, ERROR_CAUSES, Route, ROUTES } from './constants';
import { store } from '@/redux/store';
import { AlertColor } from '@mui/material';
import { red, green, yellow, grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles/createTheme';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { generatePath } from 'react-router';

const locales: {
  [key: string]: any;
} = {
  en: import('dayjs/locale/en'),
  // de: import('dayjs/locale/de'),
  // it: import('dayjs/locale/it'),
  // TODO: Add here needed supported languages
};

/**
 * * Given a language string set dayjs locale to specified language or 'en' as default/fallback
 * @param language string, eg: it, en, en-us
 * @returns void
 */
export const setDayJsLocale = (language?: string) => {
  if (!language || !Object.keys(locales).find((key: string) => key === language)) {
    dayjs.locale('en');
    return;
  }

  locales[language].then(() => dayjs.locale(language));
};

export const getPermissionCheckLoader = (rolesAllowed: string[]) => async () => {
  let roles: string[] = store.getState().auth.roles;

  function _checkPermission(_resolve: (value: unknown) => void, _reject: (reason?: any) => void) {
    if (roles.filter((role) => rolesAllowed.includes(role)).length === 0) {
      return _reject(new Error('Forbidden!', { cause: ERROR_CAUSES.UNAUTHORIZED_ROLE }));
    }
    return _resolve(new Response());
  }

  if (roles && roles.length > 0) {
    // roles available when in-app navigation
    return _checkPermission(Promise.resolve.bind(Promise), Promise.reject.bind(Promise));
  } else {
    // roles not rehydrated yet when URL is loaded directly
    return new Promise((resolve, reject) => {
      store.subscribe(() => {
        roles = store.getState().auth.roles;
        _checkPermission(resolve, reject);
      });
    });
  }
};

export const isValidJSON = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch {
    return false;
  }
};

export const removeFieldsFromJson = (jsonData: any) => {
  const updatedJsonData = { ...jsonData };
  Object.keys(updatedJsonData).forEach((category) => {
    updatedJsonData[category] = updatedJsonData[category]?.map((item: any) => {
      const { role, view, createdAt, updatedAt, ...newItem } = item;
      return newItem;
    });
  });

  return updatedJsonData;
};

export class DateRangeDefaultValue {
  start: Dayjs;
  end: Dayjs;

  constructor(start: Dayjs, end: Dayjs) {
    this.start = start;
    this.end = end;
  }

  unix = () => ({
    start: this.start.valueOf(),
    end: this.end.valueOf(),
  });

  toUnixAsString = () => ({
    start: this.start.valueOf().toString(),
    end: this.end.valueOf().toString(),
  });
}

/**
 * Normalizes a value to a percentage between a specified range.
 *
 * @param {number} value The value to normalize.
 * @param {number} min The lower bound of the range.
 * @param {number} max The upper bound of the range.
 * @returns {number} The normalized value, expressed as a percentage of the range, rounded to the nearest integer.
 *
 * @example
 * // Normalize a value of 5 in a range from 0 to 10
 * const result = wrapInRange(5, 0, 10);
 * console.log(result); // Output: 50
 */
export const wrapInRange = (value: number, min: number, max: number): number =>
  Math.round(((value - min) * 100) / (max - min));

/**
 * Returns a function that applies `fn` to the first `limit` elements of an array.
 * Remaining elements are mapped to `null`.
 *
 * @template T Type of array elements.
 * @template R Return type of `fn`.
 * @param {number} limit Max elements to apply `fn` to.
 * @param {(value: T, index: number) => R} fn Function to apply.
 * @returns {((value: T, index: number) => R | null)} New function.
 *
 * @example
 * // Given a function that doubles a number
 * const double = (num) => num * 2;
 * // Apply the function to an array just for the first 3 elements
 * const result = [1, 2, 3, 4, 5].map(limit(3, doubleFirstThree);
 * console.log(result); // Output: [2, 4, 6, null, null]
 */
export const limit = <T, R>(
  limit: number,
  fn: (value: T, index: number) => R
): ((value: T, index: number) => R | null) => {
  return (value: T, index: number) => (index < limit ? fn(value, index) : null);
};

/**
 * Parse a camelCase string to a kebab-string
 * @param {string} camelCaseString A camelCase string
 * @returns {string} a kebab-case string
 */
export const camelToKebab = (camelCaseString: string) =>
  camelCaseString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * Copies a given text to the clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @param {() => void} onSucces - The callback to be executed when the text is successfully copied.
 * @param {() => void} onFail - The callback to be executed when the copy operation fails.
 *
 * @example
 * copyToClipboard(
 *   'Hello, world!',
 *   () => console.log('Copying to clipboard was successful!'),
 *   (err) => console.error('Could not copy text: ', err)
 * );
 */
export const copyToClipboard = (payload: { text: string; onSucces: () => void; onFail: () => void }) => {
  const { text, onSucces, onFail } = payload;
  return navigator.clipboard ? navigator.clipboard.writeText(text).then(onSucces).catch(onFail) : onFail();
};

/**
 * Helper function to compose and format data for the user clipboard.
 *
 * @param {Array} data - An array with the configuration of the data to be copied.
 * @returns {string} The data formatted to be copied to the clipboard.
 * @example
 *   const toClipboardData = composeClipboardData<DeviceTooltipContentProps>([
 *   ['Device Model', data.model],
 *   ['Device Name', data.name],
 *   ['Device ID', data.serialNumber],
 *   ['Position', `${data.coordinates.lat}, ${data.coordinates.lng}`],
 * ]);
 */
export const composeClipboardData = <T>(data: [string, T[keyof T]][]): string => {
  return data.map(([label, value]) => `${label}: ${value}`).join('\n');
};

export const dispatchToast = ({
  message,
  severity,
  position,
}: {
  message: string;
  severity: AlertColor;
  position: { x: number; y: number };
}) => {
  const positionRelativeToScroll = { x: position.x, y: position.y + window.scrollY };
  const event = new CustomEvent('toast', { detail: { message, severity, position: positionRelativeToScroll } });
  window.dispatchEvent(event);
};

/**
 * Clamp a value between a min and a max value
 * @param {number} curr Current value
 * @param {number=} min Minimum accepted value
 * @param {number=} max Max value
 * @returns {number}
 */
export const clamp = (curr: number, min?: number, max?: number) =>
  (min || 0) * +((min && curr < min) || 0) +
  (max || 0) * +((max && curr > max) || 0) +
  curr * +(curr >= (min || curr) && curr <= (max || curr));

/**
 * Get colors from percentage
 * @param {number} value Percentage
 * @returns {string} hexadecimal color
 */
export const getColorsFromPercentage = (value: number) => {
  if (value >= 50 && value <= 100) {
    return green['500'];
  }
  if (value > 20 && value < 50) {
    return yellow['500'];
  }
  if (value <= 20 && value >= 0) {
    return red['500'];
  }
  return grey['500'];
};

export const isValidNumber = (n: null | number | undefined | string) => n != null && typeof n === 'number' && !isNaN(n);

export const isEmptyOrUndefinedObject = <T extends {}>(obj?: T) => {
  if (!obj) return true;

  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

/**
 * Returns a hex color based on the seed (string) provided
 * @param {string} str Seed to generate the hex
 * @returns {string} Hex code generated
 */
export const stringToColor = (str: string) => {
  let hash = 0;
  // Calculate a hash value based on the characters in the string
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  // Convert the hash value to an RGB color
  const r = (hash >> 16) & 0xff;
  const g = (hash >> 8) & 0xff;
  const b = hash & 0xff;

  // Convert RGB to hex format
  const colorHex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

  return colorHex;
};

/**
 * Repeat each `str` character n times
 * @param {string} str The string of which characters needs to be repeated
 * @param {number} times How many times to repeat each character
 * @returns {string} String with repeated characters
 */
export const repeatCharacters = (str: string, times: number) => {
  return [...str].flatMap((c) => new Array(times).fill(c)).join('');
};

/**
 * Given a string it returns a string of same characters in a different position
 * @param str String to be mixed
 * @returns {string} Mixed string
 */
export const mixStringDeterministic = (str: string) => {
  const chars = [...str]; // Convert the string into an array of characters
  const length = chars.length;
  const mixedChars = new Array(length).fill(null); // Initialize an array to hold the mixed characters

  // Iterate over each character
  for (let i = 0; i < length; i++) {
    const char = chars[i];
    const unicodeValue = char.charCodeAt(0); // Get the Unicode value of the character
    let newIndex = Math.floor((unicodeValue / (i + 1)) % length); // Calculate the new index

    // Find the first available position in the mixedChars array
    while (mixedChars[newIndex] !== null) {
      newIndex = (newIndex + 1) % length; // Ensure the index wraps around if it exceeds the length
    }

    mixedChars[newIndex] = char; // Place the character at the new index
  }

  return mixedChars.join(''); // Convert the array back into a string
};

export const typeObject = <T>(obj: T) => obj;

export const getChipColor = (index: number, theme: Theme) =>
  index === 0
    ? theme.palette.background.podium.gold
    : index === 1
    ? theme.palette.background.podium.silver
    : theme.palette.background.podium.bronze;

export const parseVersion = (version: string | Array<string>): string | Array<string> => {
  // Matches numbers
  const pattern = /[^0-9.]/g;
  if (Array.isArray(version)) {
    return version.map((v) => v.replace(pattern, ''));
  }
  return version.replace(pattern, '');
};

export const isGreaterVersion = (toBeNewer: string, toCompareWith: string) => {
  const toBeNewerParsed = parseVersion(toBeNewer) as string;
  const toCompareWithParsed = parseVersion(toCompareWith) as string;

  const oldParts = toCompareWithParsed.split('.');
  const newParts = toBeNewerParsed.split('.');
  let i = 0;
  for (i; i < newParts.length; i++) {
    const a = ~~newParts[i]; // we convert to number with ~~ operator
    const b = ~~oldParts[i]; // we convert to number with ~~ operator
    if (a > b) {
      return true;
    }
    if (a < b) {
      return false;
    }
  }
  return false;
};

/**
 * Generates the full path for a given route ID.
 *
 * @param {APP_ROUTE_ID} id - The ID of the route.
 * @param {Record<string, string | null>} [params] - Optinal params to be replaced inside path.
 * @param {string} [path] - An optional path to append.
 * @returns {string} The full path for the given route ID.
 */
export const getPath = (id: APP_ROUTE_ID, params?: Record<string, string | null>, path?: string): string => {
  const route = ROUTES[id] as Route;
  if (route.parent) {
    return getPath(route.parent, params, `/${ROUTES[id].fragment}${path ?? ''}`);
  }

  return generatePath(`/${ROUTES[id].fragment}${path ?? ''}`, params);
};

export const getRouteByFragment = (
  (routes) => (fragment: string) =>
    routes.filter((v) => v[1].fragment === fragment)
)(Object.entries(ROUTES) as [APP_ROUTE_ID, Route][]);

/**
 * Retrieves all child route IDs for a given route ID.
 *
 * @param {APP_ROUTE_ID} id - The ID of the route.
 * @param {APP_ROUTE_ID[]} [children] - An optional array of child route IDs.
 * @returns {APP_ROUTE_ID[]} An array of all child route IDs.
 */
export const getChildIds = (
  (routes) =>
  (id: APP_ROUTE_ID, children?: APP_ROUTE_ID[]): APP_ROUTE_ID[] => {
    const _children = routes.filter((route) => route[1].parent === id);

    if (_children.length === 0) {
      return [...(children ?? []), id];
    }

    const _c = _children.reduce<APP_ROUTE_ID[]>((acc, route) => {
      const _children = routes.filter((_route) => _route[1].parent === route[0]);

      if (_children.length === 0) {
        return [...(acc ?? []), route[0]];
      }

      return getChildIds(route[0], [...(acc ?? []), route[0]]);
    }, []);

    return [...(children ?? []), ..._c];
  }
)(Object.entries(ROUTES) as [APP_ROUTE_ID, Route][]);

/**
 * Returns 0{n} if the number is lower than 10
 * the number if it's higher
 * @param index
 * @returns string
 */
export const prefixedNumber = (index: number) => `${+(index + 1 >= 10) && ''}${index + 1}`;

export const add = (x: number) => (y: number) => x + y;
export const subtract = (x: number) => (y: number) => y - x;
export const toOneBasedIndex = add(1);
export const toZeroBasedIndex = subtract(1);
