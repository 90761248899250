/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable-next-line no-redeclare */

import { Column, MaterialTableProps, Query } from '@material-table/core';
import { SxProps } from '@mui/material';
import { MutableRefObject } from 'react';
import { Schema as S } from '@effect/schema';

export interface GenericExportTableProps<T extends object & { id: string }>
  extends Table<T>,
    Omit<MaterialTableProps<T>, 'title' | 'data'> {
  /** Custom hook that returns the desired columns and data formatting for the table */
  columns: Array<Column<T>>;
}

export interface ExportTableProps<T extends object> extends MaterialTableProps<T> {
  title: string;
  data: T[];
  columns: Column<T>[];
  searchText?: string;
  handleSelection: (data: T[]) => void;
  filterRowsBySelected: (data: T[], selectedRowsID: MutableRefObject<string[]>) => T[];
  selectedRowsID: MutableRefObject<string[]>;
  exportData?: boolean;
  selection?: boolean;
  search?: boolean;
  sx?: SxProps;
  toolbar?: boolean;
  nonHideableColumns?: string | string[];
}

export interface Table<T> {
  data: T[];
  title: string;
  exportData: boolean;
  selection: boolean;
}

export const Direction = S.Literal('asc', 'desc');
export type Direction = typeof Direction.Type;

export type TableRef<T extends object> =
  | {
      onQueryChange: (query: Partial<Query<T>>, callback?: () => void) => void;
    }
  | undefined;

export const BaseTableFilterState = S.Struct({
  page: S.optional(S.NumberFromString),
  size: S.optional(S.NumberFromString),
  direction: S.optional(Direction),
  orderBy: S.optional(S.String),
});
