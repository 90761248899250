import { Box, Button, CardContent, Typography, cardContentClasses } from '@mui/material';
import { OverviewCardProps } from '../typings';
import { useTranslation } from 'react-i18next';
import { CulliganCard } from '@/components/Shared/Card';

const OverviewCard = ({
  title,
  subtitle,
  data,
  onActionClick,
  actionLabel,
  children,
  cardContentProps,
  contentContainer = 'card',
  cardHeader = true,
  cardContainerProps,
  ...props
}: OverviewCardProps) => {
  const { t } = useTranslation();

  const renderValue = (Value: string | React.FC | JSX.Element) => {
    const flex = 1.5;
    if (typeof Value === 'string') {
      return (
        <Typography variant="body1" sx={{ flex, textAlign: 'left', whiteSpace: 'pre' }}>
          {Value}
        </Typography>
      );
    }
    return <Box flex={flex}>{typeof Value === 'function' ? <Value /> : Value}</Box>;
  };

  return (
    <Box {...props} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems={'center'} mb="1rem" minHeight={'36.5px'}>
        <Typography variant="h6" sx={{ pt: 0 }} fontWeight={400}>
          {title}
        </Typography>
        {typeof subtitle === 'function' && subtitle()}

        {onActionClick && (
          <Button variant="text" onClick={() => onActionClick()} sx={{}}>
            {actionLabel || t('edit')}
          </Button>
        )}
      </Box>
      {contentContainer === 'card' ? (
        <CulliganCard sx={{ flex: 1, overflowY: 'auto', ...(cardContainerProps?.sx ? cardContainerProps.sx : {}) }}>
          <CardContent
            {...cardContentProps}
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              ...cardContentProps?.sx,
              [`&.${cardContentClasses.root}:last-child`]: {
                pb: 2,
              },
            }}
          >
            {data &&
              Object.entries(data).map(([k, value]) => (
                <Box key={k} display="flex" alignItems="flex-start" gap="2rem" my="0.5rem">
                  <Typography variant="body1" sx={{ fontWeight: 600, flex: 1, textAlign: 'right' }}>
                    {`${k}`}
                  </Typography>
                  {renderValue(value)}
                </Box>
              ))}
            {children && children}
          </CardContent>
        </CulliganCard>
      ) : (
        <Box sx={{ flex: 1 }}>
          <CardContent
            {...cardContentProps}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              ...cardContentProps?.sx,
            }}
          >
            {children && children}
          </CardContent>
        </Box>
      )}
    </Box>
  );
};

export default OverviewCard;
