import { Wrapper as MapContainer, Status } from '@googlemaps/react-wrapper';
import { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import APP_CONFIG from '@/appConfig';
import { MarkerInfo } from './typings';
import MarkersManager from './MarkersManager';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Map = ({
  markers,
  noMarkersMessage,
  mapId,
}: {
  markers: MarkerInfo[];
  noMarkersMessage?: string;
  mapId: string;
}) => {
  const { t } = useTranslation();
  const [markersManager, setMarkersManager] = useState<MarkersManager | null>();

  const onMapLoaded = useCallback((map: google.maps.Map) => {
    setMarkersManager(
      new MarkersManager(map, new MarkerClusterer({ map, algorithm: new SuperClusterAlgorithm({ radius: 200 }) }))
    );
  }, []);

  const renderStatus = useCallback(
    (status: Status): ReactElement => {
      const message = status === Status.FAILURE ? t('mapLoadingError') : t('mapLoading');
      return <div role="banner">{message}</div>;
    },
    [t]
  );

  useEffect(() => {
    if (markersManager) {
      markersManager
        .clearAll()
        .preventCoordinatesCollision(markers)
        .forEach((m) => markersManager.addMarker(m));
      markersManager.updateClusterer().reloadMap();
    }
  }, [markers, markersManager]);

  return (
    <Fragment>
      <MapContainer apiKey={APP_CONFIG.gmapsApiKey} render={renderStatus}>
        <>
          <MapContent onMapLoaded={onMapLoaded} />
          {!markers.length && (
            <Typography
              variant="h5"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.50)',
                color: 'white',
                borderRadius: '0.5rem',
                padding: '1rem',
                textAlign: 'center',
              }}
            >
              {noMarkersMessage || t('noMarkers')}
            </Typography>
          )}
        </>
      </MapContainer>
    </Fragment>
  );
};

const MAP_OPTIONS = {
  center: { lat: 50, lng: -46 },
  streetViewControl: true,
  mapTypeControl: false,
  fullscreenControl: false,
};

const MapContent = ({ onMapLoaded }: { onMapLoaded: (map: google.maps.Map) => void }) => {
  const mapContainer = useRef(null);

  useEffect(() => {
    if (!mapContainer.current) return;
    const map = new window.google.maps.Map(mapContainer.current, MAP_OPTIONS);
    onMapLoaded(map);
  }, [onMapLoaded]);

  return (
    <div
      ref={mapContainer}
      id="map"
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default Map;
