import React, { useCallback } from 'react';
import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import { copyToClipboard, dispatchToast, isEmptyOrUndefinedObject, isValidNumber } from '@/shared/utils';
import { useTranslation } from 'react-i18next';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Device } from '../../../DevicesPanel/typings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CopyChip from '@/components/Shared/Chips/CopyChip';

function Address({ stringAddress, device }: { stringAddress: string; device: Device }) {
  const { t } = useTranslation();

  const _Address = useCallback(() => {
    switch (true) {
      case !!stringAddress:
        return (
          <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
            {Object.entries(device?.installationAddress || {}).map(([key, value]) => (
              <Box key={key}>
                <Typography variant="body2" color="text.secondary">
                  {t(key)}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {value || t('notAvailable')}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      case device.detectedLocation && !isEmptyOrUndefinedObject(device.detectedLocation):
        return (
          <>
            <Box display="flex" gap={1}>
              <CopyChip
                valueToCopy={`lat: ${device.detectedLocation.lat}, lon: ${device.detectedLocation.lon}`}
                icon={<LocationOnIcon />}
                label={`${t('latitude')} ${device.detectedLocation.lat}`}
              />
              <CopyChip
                valueToCopy={`lat: ${device.detectedLocation.lat}, lon: ${device.detectedLocation.lon}`}
                icon={<LocationOnIcon />}
                label={`${t('longitude')} ${device.detectedLocation.lon}`}
              />
              <Chip
                size="small"
                icon={<ShareLocationIcon />}
                label={`${t('accuracy')} ${device.detectedLocation.accuracy} ${t('meters')}`}
              />
            </Box>
            <Typography variant="caption" sx={{ mt: 0.5 }}>
              {t('detectedLocation')}
            </Typography>
          </>
        );
      case isValidNumber(device.lat) && isValidNumber(device.lon):
        return (
          <>
            <Box display="flex" gap={1}>
              <CopyChip
                valueToCopy={`lat: ${device.lat}, lon: ${device.lon}`}
                icon={<LocationOnIcon />}
                label={
                  <>
                    {t('latitude')} {device.lat}
                  </>
                }
              />
              <CopyChip
                valueToCopy={`lat: ${device.lat}, lon: ${device.lon}`}
                icon={<LocationOnIcon />}
                label={
                  <>
                    {t('longitude')} {device.lon}
                  </>
                }
              />
            </Box>
          </>
        );

      default:
        return (
          <Typography variant="body1" fontWeight={400}>
            {t('addressNotAvailable')}
          </Typography>
        );
    }
  }, [device.detectedLocation, device?.installationAddress, device.lat, device.lon, stringAddress, t]);

  return (
    <Area
      paddingRenderer="section"
      {...(stringAddress
        ? {
            onClick: (e) =>
              copyToClipboard({
                text: stringAddress,
                onSucces: () => {
                  dispatchToast({
                    message: t('copyToClipboardSuccess'),
                    severity: 'success',
                    position: { x: e.clientX, y: e.clientY },
                  });
                },
                onFail: () => {
                  dispatchToast({
                    message: t('copyToClipboardError'),
                    severity: 'error',
                    position: { x: e.clientX, y: e.clientY },
                  });
                },
              }),
          }
        : {})}
    >
      <AreaHeader
        sx={{
          ...(!stringAddress ? { mb: 2 } : {}),
        }}
      >
        <Subtitle>{t('location')}</Subtitle>
        {stringAddress && (
          <IconButton size="small">
            <ContentCopyIcon />
          </IconButton>
        )}
      </AreaHeader>
      <AreaBody>{_Address()}</AreaBody>
    </Area>
  );
}
export default React.memo(Address);
