import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FleetAlarm } from '../typings';
import { useTranslation } from 'react-i18next';
import useAlarmColumns from './useAlarmsColumn';
import { useState } from 'react';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { DeviceAlarm } from '../../Devices/DevicesPanel/typings';
import { Column, MaterialTableProps } from '@material-table/core';
import { ROUTES } from '@/shared/constants';

export const SafeTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<FleetAlarm | DeviceAlarm>)
);

export default function AlarmsTable({
  items,
  isFleet,
  excludeColumns,
  ...props
}: { items: FleetAlarm[] | DeviceAlarm[]; isFleet: boolean; excludeColumns?: string[] } & Omit<
  MaterialTableProps<any>,
  'columns' | 'data'
>) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedAlarm, setSelectedAlarm] = useState<FleetAlarm | DeviceAlarm | null>(null);

  const handleOpenDialog = (id: string) => {
    const alarm =
      (isFleet && (items as FleetAlarm[]).find((alarm) => alarm.id === id)) ||
      ((!isFleet && (items as DeviceAlarm[]).find((alarm) => alarm.id === id)) as FleetAlarm | DeviceAlarm);
    setSelectedAlarm(alarm);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedAlarm(null);
    setOpenDialog(false);
  };

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'alarm',
        label: t('alarm'),
        body: selectedAlarm && <IssueDetails data={selectedAlarm} isFleet={isFleet} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  const columns = useAlarmColumns({
    onDetailClick: handleOpenDialog,
    excludeColumns: excludeColumns,
    isFleetAlarm: isFleet,
  });

  return (
    <>
      {isFleet && (
        <GenericExportTable<FleetAlarm>
          {...props}
          title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
          data={items as FleetAlarm[]}
          columns={columns as Column<FleetAlarm>[]}
          exportData={true}
          selection={true}
        />
      )}
      {!isFleet && (
        <GenericExportTable<DeviceAlarm>
          {...props}
          title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
          data={items as DeviceAlarm[]}
          columns={columns as Column<DeviceAlarm>[]}
          exportData={true}
          selection={true}
        />
      )}

      {openDialog && dialog}
    </>
  );
}
