import { Box, Typography, useTheme } from '@mui/material';
import OverviewCard from './OverviewPanel/DeviceCardInfo';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import { Device, DeviceConnectionHistoryItem } from '../DevicesPanel/typings';
import { OverviewCardData } from './typings';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import ConnectionsGraph from './ConnectionsGraph';
import CustomerServiceCard from './OverviewPanel/CustomerServiceCard';
import useIsTablet from '@/hooks/useIsTablet';
import { ROLES, ROUTES } from '@/shared/constants';
import { User } from '@typings';
import dayjs from 'dayjs';
import DeviceConsumableCardOverview from './OverviewPanel/ConsumableCard';
import { getPath, limit } from '@/shared/utils';
import { FleetError } from '../../AlarmsAndErrors/typings';
import AlarmsTable from '../../AlarmsAndErrors/Alarms/AlarmsTable';
import ErrorsTable from '../../AlarmsAndErrors/Errors/ErrorsTable';
import DeviceInfoCard from './OverviewPanel/InfoCard';
import ProfileCard from './OverviewPanel/ProfileCard';
import {
  usePostLogLevelCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostPowerProfileCommandMutation,
  usePostRebootCommandMutation,
} from '@/redux/api/admin/deviceCommandsApiSlice';
import useDeviceInfoPollings from './OverviewPanel/InfoCard/useDeviceInfoPollings';
import { usePostOTAJobMutation } from '@/redux/api/admin/otaApiSlice';
import LoyaltyProgramTable from './OverviewPanel/LoyaltyProgramTable';

export type ParsedInfo = {
  deviceInfo: {
    [x: string]:
      | string
      | ('2G' | '4G' | '5G' | 'BLE' | 'LTE cat 1-4' | 'LTE cat M1' | 'Nb-IoT 2' | 'NB-IoT' | 'Sidewalk' | 'Wi-Fi')[]
      | undefined;
  };
  customer: OverviewCardData;
  locationInfo: {
    [key in string]: string;
  };
};

function DevicePanel({
  device,
  customer,
  connectionHistory,
}: {
  device: Device;
  customer: User;
  connectionHistory: DeviceConnectionHistoryItem[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const [postOperatingMode, operatingModeFlags] = usePostOperatingModeCommandMutation();
  const [postPowerProfile, powerProfileFlags] = usePostPowerProfileCommandMutation();
  const [postLogLevel, logLevelFlags] = usePostLogLevelCommandMutation();
  const [postReboot, rebootFlags] = usePostRebootCommandMutation();
  const [postOTAJob, otaJobFlags] = usePostOTAJobMutation();
  const _flags = {
    postOperatingModeCommand: operatingModeFlags,
    postPowerProfileCommand: powerProfileFlags,
    postLogLevel: logLevelFlags,
    postOTAJob: otaJobFlags,
    postRebootCommand: rebootFlags,
  };
  const { otaPollingRequestedAt } = useDeviceInfoPollings({ flags: _flags, serialNumber: device?.serialNumber || '' });

  const isLoyaltyProgramActive = device && device?.loyaltyPrograms && device?.loyaltyPrograms?.length > 0;

  const info: ParsedInfo | undefined = useMemo(() => {
    if (device) {
      const status = device?.status?.connection?.online ? t('connected') : t('disconnected');
      const lastUpdate = dayjs(device.updatedAt).format('YYYY/MM/DD') || t('notAvailable');
      let c: OverviewCardData;
      if (customer) {
        c = {
          [t('fullName')]: `${customer.firstName} ${customer.lastName}`,
          [t('mobile')]: customer.mobilePhoneNumber,
          [t('email')]: customer.email,
          [t('location')]: customer.city,
          [t('address')]: customer.address,
          [t('company')]: customer.company,
        };
      } else {
        c = { [t('noCustomerKey')]: t('noCustomerValue')! };
      }
      const deviceAddress: string = device.installationAddress?.address || t('notAvailable');
      return {
        deviceInfo: {
          [t('id')]: device.serialNumber,
          [t('name')]: device.name,
          [t('model')]: device.model,
          [t('status')]: `${status}\n(last update: ${lastUpdate})`,
          [t('firmwareVersion')]: device.swVersion || 'N/A',
          [t('operatingMode')]: device.operatingMode || 'N/A',
          [t('connectivity')]: device.connectivity || 'N/A',
        },
        customer: c,
        locationInfo: {
          [t('address')]: deviceAddress,
        },
      };
    }
  }, [device, customer, t]);

  return info?.deviceInfo ? (
    <Box
      display={isTablet ? 'flex' : 'grid'}
      flexDirection={'column'}
      gridTemplateColumns="repeat(auto-fill, minmax(33rem, 1fr))"
      alignItems="stretch"
      justifyItems={'stretch'}
      columnGap="2rem"
      rowGap="2rem"
      sx={{
        [theme.breakpoints.up(1600)]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up(1800)]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      }}
    >
      <DeviceInfoCard
        device={device}
        info={info}
        postOTAJob={postOTAJob}
        postReboot={postReboot}
        otaPollingRequestAt={otaPollingRequestedAt}
      />

      <ProfileCard
        device={device}
        postLogLevel={postLogLevel}
        postOperatingMode={postOperatingMode}
        postPowerProfile={postPowerProfile}
      />

      {customer && customer.globalRoles.length === 1 && customer.globalRoles.includes(ROLES.USER) ? (
        <CustomerServiceCard />
      ) : (
        <OverviewCard
          id="deviceCustomerInfoCard"
          title={t('customer')}
          textAlign={'center'}
          onActionClick={Object.keys(info.customer).length > 1 ? () => undefined : undefined}
        >
          {`${t('noCustomerForDevice')}`}
        </OverviewCard>
      )}

      <OverviewCard
        id="deviceAlarmsAndErrorsCard"
        title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
        onActionClick={() =>
          navigate(getPath('ONETOOL_CHANNELS_ALARMS'), {
            state: { switchTab: true },
          })
        }
        actionLabel={t('seeAll')!}
        contentContainer="box"
        cardContentProps={{ sx: { p: 0 } }}
        sx={{
          p: 0,
          justifyContent: 'normal',
          '> div div': { justifyContent: 'normal' },
          '& table': { tableLayout: 'fixed !important' },
          '& table td': { overflow: 'hidden' },
        }}
      >
        <AlarmsTable
          items={device.alarms || []}
          isFleet={false}
          options={{ toolbar: false, selection: false, paging: true, pageSize: 4 }}
          excludeColumns={['actions', 'deviceName']}
        />
      </OverviewCard>

      <OverviewCard
        id="deviceErrorsCard"
        title={t('errors')}
        actionLabel={t('seeAll')!}
        cardContentProps={{ sx: { p: 0 } }}
        contentContainer="box"
        onActionClick={() => navigate(`/fleet/devices/${device.serialNumber}#errors`, { state: { switchTab: true } })}
      >
        <ErrorsTable
          items={(device.errors as FleetError[]) || []}
          isFleet={false}
          options={{ toolbar: false, selection: false, paging: false }}
          excludeColumns={['actions', 'deviceName']}
        />
      </OverviewCard>

      <OverviewCard
        title="Consumables"
        actionLabel={t('seeAll')!}
        contentContainer="card"
        sx={{
          justifyContent: 'normal',
        }}
        cardContentProps={{ sx: { p: 0 } }}
        onActionClick={() =>
          navigate(`/fleet/devices/${device.serialNumber}#consumables`, { state: { switchTab: true } })
        }
      >
        {device?.consumables && device.consumables?.length > 0 ? (
          device.consumables.map(
            limit(3, (consumable) => (
              <DeviceConsumableCardOverview
                key={consumable?.consumable.id + consumable.index}
                consumable={consumable}
              />
            ))
          )
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noConsumablesForDevice')}
          </Typography>
        )}
      </OverviewCard>

      <OverviewCard
        id="loyaltyProgramCard"
        actionLabel={t('seeAll')!}
        title={t('loyalty_programs')}
        cardContentProps={{ sx: { p: 0 } }}
        contentContainer="box"
        onActionClick={() => navigate(getPath('ONETOOL_CHANNELS_LOYALTY_PROGRAMS'), { state: { switchTab: true } })}
      >
        {isLoyaltyProgramActive ? (
          <LoyaltyProgramTable
            items={device.loyaltyPrograms!}
            serialNumber={device.serialNumber}
            isFleet={false}
            options={{ toolbar: false, selection: false, paging: false, search: false }}
            excludeColumns={['updatedAt', 'createdAt', 'edit', 'delete']}
          />
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noLoyaltyProgramForDevice')}
          </Typography>
        )}
      </OverviewCard>

      <OverviewCard
        id="deviceConnectionHistoryCard"
        title={t('connectionHistory')}
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: -1,
        }}
        cardContentProps={{
          sx: {
            ':last-child': {
              p: theme.spacing(2),
            },
          },
        }}
      >
        {connectionHistory.length > 0 ? (
          <ConnectionsGraph history={connectionHistory} />
        ) : (
          <Typography variant="body1" textAlign={'center'}>
            {t('noConnectionHistoryForDevice')}
          </Typography>
        )}
      </OverviewCard>
    </Box>
  ) : null;
}

export default withErrorLoadingManagement(DevicePanel);
