import { OTARes } from '@/components/OneTools/Ecosystem/OTA/typings';
import { SELECT_CARD_ROLES } from '@/components/Shared/AddEditDialog/SelectCardInput';
import Area, { AREA_DESIGN_TOKENS, AreaRow, DataBoundAreaRow } from '@/components/Shared/Card/Area';
import { Body, Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import ConfirmDialog from '@/components/Shared/ConfirmDialog';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { usePostRebootCommandMutation } from '@/redux/api/admin/deviceCommandsApiSlice';
import {
  useGetOTAJobsDevicesListQuery,
  useLazyGetOTAListByDeviceIdQuery,
  usePostOTAJobMutation,
} from '@/redux/api/admin/otaApiSlice';
import { getPath, isEmptyOrUndefinedObject, isGreaterVersion, isValidNumber } from '@/shared/utils';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { alpha, Box, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ParsedInfo } from '..';
import { Device } from '../../DevicesPanel/typings';
import Address from './InfoCard/Address';
import Firmware from './InfoCard/Firmware';
import Identity from './InfoCard/Identity';
import Status from './InfoCard/Status';
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';
import {
  selectHasPendingOperatingMode,
  selectPendingCommands,
  selectHasPendingOTAUpdate,
} from '@/redux/slices/deviceSlice';
import { AppState } from '@/redux/store';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
import { useOperatingModeColors } from './InfoCard/useOperatingModeColors';
import { OperatingMode } from '@culligan-iot/domain/culligan/one/device';
import { CulliganCard } from '@/components/Shared/Card';
import { Circle } from './MapCircle';

function getLocation(device: Device) {
  const { lat, lon, detectedLocation } = device;
  if (!isValidNumber(lat) && !isValidNumber(lon) && isEmptyOrUndefinedObject(detectedLocation)) {
    return undefined;
  }

  return {
    lat: lat ?? detectedLocation!.lat,
    lng: lon ?? detectedLocation!.lon,
    ...(detectedLocation && !lat ? { accuracy: detectedLocation.accuracy } : {}),
  };
}

function DeviceInfoCard({
  device,
  info,
  postOTAJob,
  postReboot,
  otaPollingRequestAt,
}: {
  device: Device;
  info: ParsedInfo;
  postOTAJob: ReturnType<typeof usePostOTAJobMutation>[0];
  postReboot: ReturnType<typeof usePostRebootCommandMutation>[0];
  otaPollingRequestAt?: number;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [triggerGetOTAListByDeviceId, result] = useLazyGetOTAListByDeviceIdQuery();
  const pendingCommands = useSelector((state: AppState) => selectPendingCommands(state, device.serialNumber));
  const hasPendingCommmands = pendingCommands.length > 0;
  const hasPendingOTAUpdate = useSelector((state: AppState) => selectHasPendingOTAUpdate(state, device.serialNumber));
  const hasPendingOperatingModeUpdate = useSelector((state: AppState) =>
    selectHasPendingOperatingMode(state, device.serialNumber)
  );
  const isLoadingReboot = pendingCommands.some((command) => command.command === 'reboot');
  const operatingModeColors = useOperatingModeColors();
  const isRebootSupported =
    device?.supportedOperatingModes?.length && device?.supportedOperatingModes.includes('Startup');
  const stringAddress = Object.values(device?.installationAddress || {}).join(', ');
  const otaJobsResult = useGetOTAJobsDevicesListQuery(device.id);

  const theme = useTheme();

  const MemoizedMap = useMemo(() => {
    const location = getLocation(device);

    return (
      <Map
        defaultZoom={15}
        {...(location ? { defaultCenter: location } : {})}
        mapId={`${device.id}-map`}
        disableDefaultUI={true}
        zoomControl={true}
        clickableIcons={false}
      >
        <RenderIf condition={!location}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                padding: 1,
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.6),
                color: (theme) => theme.palette.getContrastText(alpha(theme.palette.grey[900], 0.2)),
                borderRadius: 2,
              }}
            >
              {t('locationNotAvailable')}
            </Box>
          </Box>
        </RenderIf>
        <RenderIf condition={!isEmptyOrUndefinedObject(location)}>
          <AdvancedMarker key={device.id} position={location}>
            <Pin
              background={theme.palette.primary.main}
              glyphColor={theme.palette.text.primary}
              borderColor={theme.palette.primary.light}
            />
          </AdvancedMarker>
        </RenderIf>
        <RenderIf condition={!isEmptyOrUndefinedObject(location) && isValidNumber(location?.accuracy)}>
          <Circle
            center={location}
            radius={location?.accuracy}
            strokeWeight={0}
            fillColor={theme.palette.primary.main}
          />
        </RenderIf>
      </Map>
    );
  }, [device, t, theme.palette.primary.light, theme.palette.primary.main, theme.palette.text.primary]);

  useEffect(() => {
    triggerGetOTAListByDeviceId(device.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentOTAVersion = useMemo(() => {
    const otaJobs = otaJobsResult.data?.data?.items || [];
    const availableOTAs = result.data?.data?.items || [];

    const sortedOTAJobs = otaJobs
      .filter((job) => job.status === 'completed')
      .sort((a, b) => new Date(b.completedAt ?? 0).getTime() - new Date(a.completedAt ?? 0).getTime());

    const lastCompletedOTAJob = sortedOTAJobs.find((job) => job.targetVersion === device.swVersion);

    if (lastCompletedOTAJob) {
      const matchingOTA = availableOTAs.find((ota) => ota.id === lastCompletedOTAJob.buildFile);

      if (matchingOTA) {
        return matchingOTA;
      }
    }
    const fallbackOTA = availableOTAs.find((OTA) => device.swVersion === OTA.version);
    if (fallbackOTA) {
      return fallbackOTA;
    }

    return null;
  }, [otaJobsResult.data, result.data, device.swVersion]);

  const isDeviceOnline = device?.status?.connection?.online;

  const canOTAUpdate =
    isDeviceOnline &&
    !hasPendingCommmands &&
    currentOTAVersion != null &&
    !hasPendingOTAUpdate &&
    device.operatingMode !== OperatingMode.enums.OTA &&
    device.operatingMode !== OperatingMode.enums.Startup;

  const getOTATooltipMessage = useCallback(
    (isPolling: boolean, isOnline: boolean, operatingMode: OperatingMode) => {
      if (isPolling) {
        return t('firmwareUpdateAlreadyRequested');
      }
      if (!isOnline) {
        return t('deviceIsntConnectedRetryLater');
      }
      if (operatingMode === OperatingMode.enums.OTA || operatingMode === OperatingMode.enums.Startup) {
        return t('cantPerformOTAinCurrentOperatingMode');
      }
    },
    [t]
  );

  const otaTooltipMessage = useMemo(
    () => getOTATooltipMessage(hasPendingOTAUpdate || false, isDeviceOnline, device.operatingMode as OperatingMode),
    [getOTATooltipMessage, hasPendingOTAUpdate, isDeviceOnline, device.operatingMode]
  );

  const parseVersionAndMinVersion = (versionString: string) => {
    const parts = versionString.split('.');
    if (parts.length <= 3) {
      return { version: versionString, minVersion: null };
    }
    const version = parts.slice(0, 3).join('.');
    const minVersion = parts.slice(3).join('.');
    return { version, minVersion };
  };

  const confirmDialogMessage = useMemo(() => {
    return (
      <Fragment>
        <DialogTitle>
          {t('reboot')} {device.name}
        </DialogTitle>
        <DialogContent>
          <Typography>{`Are you sure you want to reboot ${device.serialNumber}?`}</Typography>
        </DialogContent>
      </Fragment>
    );
  }, [device.name, device.serialNumber, t]);

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: t('firmware'),
    baseConfig: [
      {
        name: 'firmwareVersion',
        type: 'selectCard',
        options: { required: t('fieldRequiredError') as string },
        selectCardConfig: {
          options: () => triggerGetOTAListByDeviceId(device.id).then((res) => res.data?.data?.items || []),
          labels: {
            current: t('artifact'),
            selected: t('artifact'),
            options: t('artifacts'),
          },
          getItemIdentifier: (item) => item.id,
          mapItemToHeaderElements: (item) => [
            <Box key={item.id + 'name'}>
              <Subtitle sx={{ fontWeight: '600', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
                {item.id}
              </Subtitle>
            </Box>,
          ],
          mapItemToBodyElements: (item, _, role) => {
            const _item = item as OTARes;
            const { version, minVersion } = parseVersionAndMinVersion(_item.version);
            return (
              <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                  }}
                >
                  <Box>
                    <Subtitle>{t('version')}</Subtitle>
                    <Title>
                      {version}
                      {role !== SELECT_CARD_ROLES.CURRENT &&
                        isGreaterVersion(currentOTAVersion?.version as string, version) && (
                          <WarningIcon color="warning" sx={{ fontSize: 12 }} />
                        )}
                    </Title>
                  </Box>
                  <RenderIf condition={minVersion != null || (_item.minVersion != null && _item.minVersion !== '')}>
                    <Box>
                      <Subtitle sx={{ fontSize: 10 }}>{t('minVersion')}</Subtitle>
                      <Body sx={{ fontWeight: '600' }}>{minVersion || _item.minVersion}</Body>
                    </Box>
                  </RenderIf>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('createdAt')}</Subtitle>
                  <Body>{dayjs(_item.createdAt).format('DD/MM/YYYY')}</Body>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('updatedAt')}</Subtitle>
                  <Body>{dayjs(_item.updatedAt).format('DD/MM/YYYY')}</Body>
                </Box>
              </Box>
            );
          },
          isCurrentItem: (item, currentValue) => item.id === currentValue,
          isWarningItem: (item) => {
            if (item.id === currentOTAVersion?.id) {
              return false;
            }
            const { version } = parseVersionAndMinVersion(item.version);
            return isGreaterVersion(currentOTAVersion?.version || '', version);
          },
          isDisabledItem: (item, currentValue) => {
            if (item.id === currentValue) return true;
            const { version } = parseVersionAndMinVersion(item.version);
            return isGreaterVersion(currentOTAVersion?.version as string, version);
          },
          currentValue: currentOTAVersion?.id as string,
        },
      },
    ],
    onSubmit: async (_, data: { firmwareVersion: string }) => {
      const ota = result.data?.data?.items.find((_ota) => _ota.id === data.firmwareVersion);
      if (!ota) {
        return;
      }

      postOTAJob({
        buildFile: ota.id,
        name: ota.id,
        version: ota.version,
        enabled: true,
        targetFilterParams: {
          serialNumbers: [device.id],
        },
        targetSelectionMode: 'filter',
      });
    },
  });

  const handleFirmwareAreaNavigation = () => navigate(getPath('FLEET_DEVICE_OTA_JOBS', { deviceId: device.id }));

  const handleFirmwareAreaClick = () => {
    if (!isDeviceOnline || hasPendingOTAUpdate || currentOTAVersion == null || !canOTAUpdate) {
      handleFirmwareAreaNavigation();
      return;
    }

    currentOTAVersion && canOTAUpdate && openDialog(' ');
  };

  return (
    <Fragment>
      <CulliganCard id="deviceInfoCard" cardTitle={t('deviceInfo')} sx={{ position: 'relative' }}>
        <AddEditDialog />
        <ConfirmDialog
          isOpen={confirmDialogOpen}
          onConfirm={() =>
            postReboot({
              serialNumber: device.serialNumber,
            })
          }
          onClose={() => setConfirmDialogOpen(false)}
          MessageComponent={confirmDialogMessage}
        />

        <AreaRow sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Identity
            sx={{ gridColumnEnd: 'span 2' }}
            serialNumber={device.serialNumber}
            model={device.model}
            name={device.name}
            metaData={device?.metaData}
            brandName={device.brandName}
          />
          <Firmware
            serialNumber={device.serialNumber}
            firmwareVersion={currentOTAVersion?.version || device.swVersion}
            tooltipMessage={otaTooltipMessage || ''}
            hasPendingOTAUpdate={hasPendingOTAUpdate}
            otaPollingRequestedAt={otaPollingRequestAt}
            openOTADialog={openDialog}
            canOTAUpdate={canOTAUpdate}
            currentOTAVersion={currentOTAVersion}
            isDeviceOnline={isDeviceOnline}
            handleFirmwareAreaClick={handleFirmwareAreaClick}
            handleFirmwareAreaNavigation={handleFirmwareAreaNavigation}
            info={info}
            sx={isRebootSupported ? { gridRowEnd: 'span 2' } : {}}
          />
          <Status
            device={device}
            isDeviceOnline={isDeviceOnline}
            info={info}
            updatedAt={device.updatedAt}
            sx={{ display: 'flex', flex: 1 }}
          />
          <RenderIf condition={isRebootSupported || false}>
            <AreaButton
              disabled={[
                { disabled: !isDeviceOnline, reason: t('cantRebootDeviceOffline') as string },
                { disabled: isLoadingReboot, reason: t('rebootInProgress') as string },
                { disabled: device.operatingMode === 'Disabled', reason: t('cantRebootWhileDisabled') as string },
                { disabled: hasPendingCommmands, reason: t('pendingCommands') as string },
                { disabled: hasPendingOperatingModeUpdate, reason: t('pendingOperatingModeUpdate') as string },
              ]}
              color={operatingModeColors.Reboot}
              handler={() => setConfirmDialogOpen(true)}
              text={t('reboot')}
              Icon={RestartAltOutlinedIcon}
              isLoading={isLoadingReboot}
            />
          </RenderIf>
        </AreaRow>

        <AreaRow sx={{ flexBasis: 0, mt: '0.5rem', width: '100%' }}>
          <DataBoundAreaRow sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Area paddingRenderer="root" sx={{ width: '100%', borderRadius: 1 }}>
              <Address stringAddress={stringAddress} device={device} />
            </Area>
            <Area paddingRenderer="section" sx={{ width: '100%' }}>
              <Box sx={{ borderRadius: 1, overflow: 'hidden', height: '350px', flexGrow: 2, position: 'relative' }}>
                {MemoizedMap}
              </Box>
            </Area>
          </DataBoundAreaRow>
        </AreaRow>
      </CulliganCard>
    </Fragment>
  );
}

export default React.memo(DeviceInfoCard);
