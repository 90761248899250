import AreaContainer, { AreaRow } from '@/components/Shared/Card/Area';
import { prefixedNumber } from '@/shared/utils';
import { Box, Button, ButtonProps, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { iconPerKind } from './utils';
import { Tag } from '@mui/icons-material';
import { DeviceConsumable } from '../../DevicesPanel/typings';

type SlotFooterProps = {
  index: number;
  slot: DeviceConsumable;
  isDeviceConnected: boolean;
  actions: {
    onClick: Function;
    icon: JSX.Element;
    color?: ButtonProps['color'];
    label: string;
    disabled?: boolean;
  }[];
};

export default function SlotFooter({ index, slot, isDeviceConnected, actions }: SlotFooterProps) {
  const { t } = useTranslation();

  return (
    <AreaRow
      sx={{
        height: '86px',
        alignItems: 'end',
        justifyContent: 'space-between',
      }}
    >
      <AreaContainer sx={{ gap: 0, flexGrow: 'unset', flexBasis: 'unset' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.grayShades[1],
              padding: '2px 6px',
              borderRadius: 1,
              textAlign: 'center',
              marginRight: 1,
            })}
          >
            <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'uppercase', fontSize: '6px' }}>
              {t('slot')}
            </Typography>
            <Typography variant="body1" color="text.primary" fontWeight={800} sx={{ lineHeight: 1, fontSize: '12px' }}>
              {prefixedNumber(index)}
            </Typography>
          </Box>
          <Typography variant="h5" color="text.primary" fontWeight={800} sx={{ lineHeight: 1, fontSize: '22px' }}>
            {slot.consumable.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            gap: 1,
            marginTop: 1,
          }}
        >
          <Chip
            icon={iconPerKind(slot.consumable.kind)}
            sx={(theme) => ({
              cursor: isDeviceConnected === true ? 'pointer' : 'default',
              backgroundColor: theme.palette.background.grayShades[1],
            })}
            label={slot.consumable.kind}
            size="small"
            color="primary"
            variant="outlined"
          />
          <Chip
            icon={<Tag />}
            sx={(theme) => ({
              cursor: isDeviceConnected === true ? 'pointer' : 'default',
              backgroundColor: theme.palette.background.grayShades[1],
            })}
            label={slot.consumable.id}
            size="small"
            color="default"
            variant="outlined"
          />
        </Box>
      </AreaContainer>
      <AreaContainer sx={{ alignItems: 'end', justifyContent: 'end', flexDirection: 'row' }}>
        {actions.map((action, i) => (
          <Button
            key={`${action.label}-${i}`}
            onClick={() => action.onClick()}
            startIcon={action.icon}
            disabled={action.disabled}
            variant="outlined"
            color={action.color}
            sx={{
              fontSize: '14px',
              borderRadius: 1,
              borderWidth: '2px',
              paddingX: 1,
              paddingY: 0.5,
              '&:hover': { border: '2px solid' },
            }}
          >
            {action.label}
          </Button>
        ))}
      </AreaContainer>
    </AreaRow>
  );
}
