import {
  useLazyGetDeviceIdentityQuery,
  usePostDeviceIdentityMutation,
  useDeleteDeviceIdentityMutation,
  usePutDeviceIdentityMutation,
  useLazyIsDeviceIdentityUniqueQuery,
} from '@/redux/api/system/deviceIdentitiesApiSlice';
import { DeviceIdentityReq, DeviceIdentityRes } from './typings';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useTranslation } from 'react-i18next';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useLazyGetTelemetryEntriesQuery } from '@/redux/api/system/telemetryApiSlice';
import { useLazyGetBrandsQuery } from '@/redux/api/system/brandsApiSlice';
import { useLazyGetAlarmsQuery } from '@/redux/api/system/alarmsApiSlice';
import { useLazyGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { useLazyGetEventsQuery } from '@/redux/api/system/eventsApiSlice';
import { useLazyGetCommandsQuery } from '@/redux/api/system/commandsApiSlice';
import { CustomErrors, FieldsUndefined } from '@typings';
import { CONNECTIVITIES, TIERS, ROUTES } from '@/shared/constants';
import { useLazyGetConfigsQuery } from '@/redux/api/system/configsApiSlice';
import { useEffect, useMemo, useState } from 'react';
import {
  AddEditDialogConfigBase,
  AddEditDialogConfigText,
  AddEditDialogConfigs,
} from '@/components/Shared/AddEditDialog/typings';
import useUniqueness from '@/hooks/useUniqueness';

import { darken, lighten, useTheme } from '@mui/material';
import { OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/one/device';

export default function useDeviceIdentityDialogs() {
  const { t } = useTranslation();

  const [getDeviceIdentity] = useLazyGetDeviceIdentityQuery();
  const [postDeviceIdentityTrigger, postDeviceIdentityFlags] = usePostDeviceIdentityMutation();
  const [putDeviceIdentityTrigger, putDeviceIdentityFlags] = usePutDeviceIdentityMutation();
  const [deleteDeviceIdentityTrigger, deleteDeviceIdentityFlags] = useDeleteDeviceIdentityMutation();
  const [isDeviceIdentityUnique] = useLazyIsDeviceIdentityUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<DeviceIdentityRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isDeviceIdentityUnique,
  });

  const [getAllBrands, { data: brandsData, isLoading, isError }] = useLazyGetBrandsQuery();
  const [getAllTelemetryEntries] = useLazyGetTelemetryEntriesQuery();
  const [getAllAlarms] = useLazyGetAlarmsQuery();
  const [getAllConsumables] = useLazyGetConsumablesQuery();
  const [getAllEvents] = useLazyGetEventsQuery();
  const [getAllCommands] = useLazyGetCommandsQuery();
  const [getAllConfigs] = useLazyGetConfigsQuery();

  const theme = useTheme();
  const OperatingModeColors: Record<OperatingMode, string> = useMemo(
    () => ({
      Standard: theme.palette.grey[100],
      Factory: theme.palette.primary.light,
      Ota: theme.palette.warning.light,
      Fault: theme.palette.error.light,
      Disabled: theme.palette.warning.dark,
      Startup: theme.palette.success.light,
      Installation: theme.palette.info.light,
      Service: theme.palette.info.dark,
    }),
    [
      theme.palette.grey,
      theme.palette.primary.light,
      theme.palette.warning.light,
      theme.palette.warning.dark,
      theme.palette.error.light,
      theme.palette.success.light,
      theme.palette.info.light,
      theme.palette.info.dark,
    ]
  );
  const PowerProfilesColors: Record<PowerProfile, string> = useMemo(
    () => ({
      Standard: theme.palette.grey[100],
      Eco: theme.palette.success.light,
      Sleep: theme.palette.sleep.light,
    }),
    [theme.palette.grey, theme.palette.sleep.light, theme.palette.success.light]
  );

  const [baseConfig, setBaseConfig] = useState<AddEditDialogConfigs>([
    {
      name: 'id',
      placeholder: t('code'),
      options: {
        required: t('fieldRequiredError') as string,
        setupValidate: (initialValue) => ({
          codeFormat: (value) => {
            if (value && !/^[0-9a-z]+$/.test(value)) {
              return t('invalidFormat') as string;
            }

            return true;
          },
          unique: (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        }),
      },
    },
    {
      name: 'name',
      placeholder: t('name'),
      options: {
        required: t('fieldRequiredError') as string,
        setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
          if (currValue === prevValue) {
            return prevResult;
          }

          return currValue === initialValue || validateUniqueness('name', currValue);
        },
      },
    },
    {
      name: 'baseProtocolVersion',
      type: 'number',
      placeholder: t('baseProtocolVersion'),
      hideControls: true,
      options: {
        required: t('fieldRequiredError') as string,
      },
      initialValue: 2,
    },
    {
      name: 'description',
      placeholder: t('description'),
    },
    {
      name: 'connectivity',
      placeholder: t('connectivity'),
      options: { required: t('fieldRequiredError') as string },
      type: 'autocomplete',
      selectConfig: {
        options: Object.values(CONNECTIVITIES).map((value) => ({ label: t(value), value })),
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
      placeholder: t(ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment),
      options: { required: t('fieldRequiredError') as string },
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllBrands().then((brands) => {
            return brands.data?.data?.items.map((t: any, i: number) => ({ label: t.name, value: t.id })) || [];
          });
        },
        multiple: true,
      },
    },
    {
      name: 'tier',
      placeholder: t('tier'),
      options: { required: t('fieldRequiredError') as string },
      type: 'autocomplete',
      selectConfig: {
        options: Object.values(TIERS).map((value) => ({ label: t(value + ''), value })),
      },
    },
    {
      name: 'type',
      placeholder: t('type'),
      options: { required: t('fieldRequiredError') as string },
    },
    {
      type: 'checkbox',
      name: 'alwaysConnected',
      placeholder: t('alwaysConnected'),
      initialValue: false,
    },
    {
      name: 'image',
      placeholder: t('image'),
      options: {
        validate: {
          isValidUrl: (url: string) => {
            if (!url) {
              return true;
            }

            // Regular expression for validating http/https URLs
            const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;

            if (!urlRegex.test(url)) {
              return t('imageMustBeValidUrl') as string;
            }
          },
        },
      },
    },
    {
      name: 'operatingModes',
      placeholder: t('operatingModes'),
      type: 'autocomplete',
      initialValue: ['Standard'],
      selectConfig: {
        options: Object.values(OperatingMode.enums).map((value) => ({
          label: t(value),
          value: value,
          fixed: value === OperatingMode.enums.Standard,
          style:
            value !== 'Standard'
              ? {
                  backgroundColor: lighten(OperatingModeColors[value as OperatingMode], 0.78),
                  color: darken(OperatingModeColors[value as OperatingMode], 0.5),
                }
              : {},
        })),
        multiple: true,
      },
    },
    {
      name: 'powerProfiles',
      placeholder: t('powerProfiles'),
      type: 'autocomplete',
      initialValue: ['Standard'],
      selectConfig: {
        options: ['Standard', 'Eco', 'Sleep'].map((value) => ({
          label: t(value),
          value: value,
          fixed: value === 'Standard',
          style:
            value !== 'Standard'
              ? {
                  backgroundColor: lighten(PowerProfilesColors[value as PowerProfile], 0.85),
                  color: darken(PowerProfilesColors[value as PowerProfile], 0.5),
                }
              : {},
        })),
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllTelemetryEntries().then((telemetry) => {
            return (
              telemetry.data?.data?.items.map((t) => ({
                label: `${t.name} (${t.id})`,
                value: t.id,
              })) || []
            );
          });
        },
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () =>
          getAllAlarms().then((alarms) =>
            (alarms.data?.data?.items || []).map((aa) => ({
              label: `${aa.name} (${aa.id})`,
              value: aa.id,
            }))
          ),
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllConsumables().then((consumables) => {
            return consumables.data?.data?.items.map((c) => ({ label: `${c.name} (${c.id})`, value: c.id })) || [];
          });
        },
        multiple: true,
        repeatable: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllEvents().then((events) => {
            return events.data?.data?.items.map((e) => ({ label: `${e.name} (${e.id})`, value: e.id })) || [];
          });
        },
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllCommands().then((commands) => {
            return commands.data?.data?.items.map((c) => ({ label: `${c.name} (${c.id})`, value: c.id })) || [];
          });
        },
        multiple: true,
      },
    },
    {
      name: ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment,
      placeholder: t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment),
      type: 'autocomplete',
      selectConfig: {
        options: () => {
          return getAllConfigs().then((configs) => {
            return configs.data?.data?.items.map((c) => ({ label: `${c.name} (${c.id})`, value: c.id })) || [];
          });
        },
        multiple: true,
      },
    },
  ]);

  useEffect(() => {
    if (!isLoading && !isError && brandsData) {
      const newDeviceCodeFields = (brandsData.data?.items.map((brand) => ({
        name: `deviceCode_${brand.id}`,
        placeholder: `${t('deviceCode')} for ${brand.name}`,
        type: 'text',
        options: {
          required: t('fieldRequiredError') as string,
        },
      })) ?? []) as Array<AddEditDialogConfigBase & AddEditDialogConfigText>;

      const filteredBaseConfig = baseConfig.filter((field: any) => !field.name.startsWith('deviceCode_'));

      const brandIndex = filteredBaseConfig.findIndex(
        (field: any) => field.name === ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment
      );

      const updatedBaseConfig = [...filteredBaseConfig];
      if (brandIndex !== -1) {
        updatedBaseConfig.splice(brandIndex + 1, 0, ...newDeviceCodeFields);
      } else {
        updatedBaseConfig.push(...newDeviceCodeFields);
      }

      setBaseConfig(updatedBaseConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsData, isLoading, isError, t]);

  const { AddEditDialog, openDialog } = useAddEditDialog<DeviceIdentityReq>({
    title: t('deviceIdentity'),
    baseConfig,
    getEditData: async (dialogId: string | boolean) => {
      const res: any = await getDeviceIdentity(dialogId as string);
      const deviceIdentity = { ...res.data?.data };

      if (deviceIdentity.implementations && typeof deviceIdentity.implementations === 'object') {
        Object.entries(deviceIdentity.implementations).forEach(([brand, code]) => {
          const deviceCodeFieldName = `deviceCode_${brand}`;
          deviceIdentity[deviceCodeFieldName] = code;
        });
      }

      return deviceIdentity;
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';
      if (!data.commands || data.commands.length === 0) {
        delete data.commands;
      }
      if (!data.consumables || data.consumables.length === 0) {
        delete data.consumables;
      }
      if (!data.telemetry || data.telemetry.length === 0) {
        delete data.telemetry;
      }
      if (!data.configs || data.configs.length === 0) {
        delete data.configs;
      }
      data.alwaysConnected = !!data.alwaysConnected;
      data.alarms = (data.alarms && data.alarms?.length > 0 && data.alarms) || undefined;
      data.events = (data.events && data.events?.length > 0 && data.events) || undefined;
      let implementations: any = {};
      data.brands.forEach((brand: any) => {
        const deviceCodeFieldName = `deviceCode_${brand}`;
        if (data[deviceCodeFieldName as keyof typeof data]) {
          implementations[brand] = data[deviceCodeFieldName as keyof typeof data];
          delete data[deviceCodeFieldName as keyof typeof data];
        }
      });
      (Object.keys(data) as Array<keyof DeviceIdentityReq>).forEach((key) => {
        if (key.startsWith('deviceCode')) {
          delete data[key];
        }
      });
      data.implementations = implementations;

      if (isEditing) {
        await putDeviceIdentityTrigger({ id: dialogId, body: data });
      } else {
        await postDeviceIdentityTrigger(data);
      }
    },
    formValidation: async (state, setCustomError, clearErrors, _, dialogId) => {
      const allBrands = brandsData?.data?.items;

      allBrands?.forEach((brand: any) => {
        const deviceCodeFieldName = `deviceCode_${brand.id}`;
        const isBrandSelected = state.brands?.some((selectedBrand: any) => selectedBrand.value === brand.id);

        if (isBrandSelected) {
          clearErrors([deviceCodeFieldName]);
        } else {
          setCustomError(deviceCodeFieldName, CustomErrors.HIDE_FIELD);
        }
      });
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('deviceIdentity'),
    message: (extra: any) => t('deleteMessage', { entity: t('deviceIdentity'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteDeviceIdentityTrigger({ id: extra.id, name: extra.name }),
  });

  return {
    AddEditDialog,
    openDialog,
    ConfirmDialog,
    confirm,
    postDeviceIdentityFlags,
    putDeviceIdentityFlags,
    deleteDeviceIdentityFlags,
  };
}
