import AlarmsTable from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsTable';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { DeviceContext } from './DeviceContext';

export default function Alarms() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }

  return (
    <Box>
      <AlarmsTable items={context.data?.alarms || []} isFleet={false} />
    </Box>
  );
}
