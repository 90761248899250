import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseTabsLayout from '@/components/Shared/TabsLayout/BaseTabsLayout';
import dayjs, { Dayjs } from 'dayjs';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { ROUTES } from '@/shared/constants';
import { useGetFilteredAlarmsQuery } from '@/redux/api/fleet/alarmsApiSlice';
import { DateRangeDefaultValue } from '@/shared/utils';
import AlarmsTable from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsTable';
import ErrorsTable from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsTable';
import { useGetFilteredErrorsQuery } from '@/redux/api/fleet/errorsApiSlice';

const AlarmsAndErrorsPanel = ({
  pageSize = 10,
  limit = 100,
  defaultRange = {
    end: dayjs().endOf('day'),
    start: dayjs().subtract(1, 'month').startOf('day'),
  },
}: {
  pageSize?: number;
  limit?: number;
  defaultRange?: {
    end: Dayjs;
    start: Dayjs;
  };
}) => {
  const defaultDateRange = new DateRangeDefaultValue(defaultRange.start, defaultRange.end);
  const { t } = useTranslation();
  const { data: filteredAlarmsRes, isLoading: isLoadingAlarms } = useGetFilteredAlarmsQuery({
    start: +defaultDateRange.start,
    end: +defaultDateRange.end,
    page: 1,
    size: limit,
  });
  const { data: filteredErrorsRes, isLoading: isLoadingErrors } = useGetFilteredErrorsQuery({
    start: +defaultDateRange.start,
    end: +defaultDateRange.end,
    page: 1,
    size: limit,
  });
  const alarms = filteredAlarmsRes?.data?.items || [];
  const errors = filteredErrorsRes?.data?.items || [];

  const tabsConfig = [
    {
      id: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      ariaControls: true,
      useHash: true,
      ContentComponent: (
        <AlarmsTable
          items={alarms}
          isFleet={true}
          isLoading={isLoadingAlarms}
          options={{ pageSize: pageSize, header: false }}
        />
      ),
    },
    {
      id: 'errors',
      label: t('errors'),
      ariaControls: true,
      useHash: true,
      ContentComponent: (
        <ErrorsTable
          items={errors}
          isFleet={true}
          isLoading={isLoadingErrors}
          options={{ pageSize: pageSize, header: false }}
        />
      ),
    },
  ] satisfies TabConfig[];

  return (
    <Box>
      <BaseTabsLayout config={tabsConfig} />
    </Box>
  );
};

export default AlarmsAndErrorsPanel;
