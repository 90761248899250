import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';

export const fleetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFleet: builder.query<any, void>({
      query: () => `${API_VERSION.v2}/fleet`,
    }),
    getFleetCustomer: builder.query<
      ApiResult<{ customer: { id: string; firstName: string; lastName: string } }>,
      { customerId: string }
    >({
      query: (params) => {
        return {
          url: `${API_VERSION.v2}/fleet/customer`,
          params,
        };
      },
    }),
  }),
});

export const { useGetFleetQuery, useGetFleetCustomerQuery } = fleetApiSlice;
