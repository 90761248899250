import { Box, CardProps, Card as MCard, Typography } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { ReactNode } from 'react';
import { BoxProps } from '@mui/material';

type CulliganCardProps = {
  children?: ReactNode;
  cardTitle?: DefaultTFuncReturn | string;
  header?: ReactNode;
  containerProps?: BoxProps;
  wrapInBox?: Boolean;
} & CardProps;

export const CulliganCard = ({
  children,
  sx,
  cardTitle,
  header,
  containerProps,
  wrapInBox = true,
  ...rest
}: CulliganCardProps) => {
  return wrapInBox ? (
    <>
      <Box sx={{ ...(containerProps?.sx ? containerProps.sx : {}) }}>
        {(cardTitle || header) && (
          <Box display="flex" justifyContent="space-between" alignItems={'center'} mb={'1rem'} minHeight={'36.5px'}>
            {cardTitle && (
              <Typography variant="h6" sx={{ pt: 0 }} fontWeight={400}>
                {cardTitle}
              </Typography>
            )}
            {header}
          </Box>
        )}
        <MCard {...rest} elevation={1} sx={{ backgroundColor: 'white', borderRadius: 0.5, padding: 2, gap: 1, ...sx }}>
          {children && children}
        </MCard>
      </Box>
    </>
  ) : (
    <MCard {...rest} elevation={1} sx={{ backgroundColor: 'white', borderRadius: 0.5, padding: 2, gap: 1, ...sx }}>
      {children && children}
    </MCard>
  );
};
