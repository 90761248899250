import { ApiResult } from '@typings';
import { ReactionReq, ReactionRes } from '@/components/OneTools/Channels/Reactions/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/reactions`;

export const reactionsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Reactions'] }).injectEndpoints({
  endpoints: (builder) => ({
    getReactions: builder.query<ApiResult<{ items: ReactionRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Reactions'],
    }),
    getReaction: builder.query<ApiResult<ReactionRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Reactions'],
    }),
    isReactionUnique: builder.query<ApiResult<{ name: boolean }>, Required<Pick<CommonIsEntityUniqueBase, 'name'>>>({
      query: (params: Required<Pick<CommonIsEntityUniqueBase, 'name'>>) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
    postReaction: builder.mutation<ApiResult<ReactionRes>, ReactionReq>({
      query: (body: ReactionReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Reactions'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
        });
      },
    }),
    putReaction: builder.mutation<ApiResult<ReactionRes>, { id: string; body: ReactionReq }>({
      query: ({ id, body }: { id: string; body: ReactionReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Reactions'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
        });
      },
    }),
    patchReaction: builder.mutation<ApiResult<ReactionRes>, { id: string; body: Partial<ReactionReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Reactions'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
        });
      },
    }),
    deleteReaction: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['Reactions'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
        });
      },
    }),
  }),
});

export const {
  useGetReactionsQuery,
  useLazyGetReactionsQuery,
  useGetReactionQuery,
  useLazyGetReactionQuery,
  useLazyIsReactionUniqueQuery,
  usePostReactionMutation,
  usePutReactionMutation,
  usePatchReactionMutation,
  useDeleteReactionMutation,
} = reactionsApiSlice;
