import { createContext } from 'react';
import { Device } from '../Devices/DevicesPanel/typings';

export const ConsumableContext = createContext<
  | {
      filteredData: Device[];
      selectedRowsID: Array<string> | undefined;
      handleSelection: (data: Device[]) => void;
      isError: boolean;
      isLoading: boolean;
    }
  | undefined
>(undefined);
