import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeviceAlarmCounter from '@/components/Fleet/Devices/DevicePanel/DeviceAlarmCounter';
import { composeClipboardData, copyToClipboard } from '@/shared/utils';
import { dispatchToast } from '@/shared/utils';
import { ROUTES } from '@/shared/constants';

const DeviceTooltipContent = (data: DeviceTooltipContentProps) => {
  const { t } = useTranslation();
  const toClipboardData = composeClipboardData<DeviceTooltipContentProps>([
    ['Device Model', data.model],
    ['Device Name', data.name],
    ['Device ID', data.serialNumber],
    ['Position', `${data.coordinates.lat}, ${data.coordinates.lng}`],
  ]);

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(2, auto)',
        justifyContent: 'space-evenly',
        gridAutoFlow: 'dense',
        justifyItems: 'center',
        maxHeight: '100%',
      }}
      mt={1}
    >
      <Typography variant="body2">{t('errors')}</Typography>
      <DeviceAlarmCounter serialNumber={data.serialNumber} data={data.errors} />

      <Typography variant="body2">{t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}</Typography>
      <DeviceAlarmCounter
        serialNumber={data.serialNumber}
        data={data.alarms}
        progressWithLabelProps={{
          innerLabelStyle: { color: 'white', fontSize: '0.8rem' },
          containerStyle: { marginLeft: '-0.5rem' },
          size: 30,
        }}
      />

      <Typography variant="body2">{t('Position')}</Typography>
      <Box>
        <Typography variant="body2">{String(data.coordinates.lat)}</Typography>
        <Typography variant="body2">{String(data.coordinates.lng)}</Typography>
      </Box>
      <Box sx={{ gridColumn: '1 / span 2' }}>
        <Button
          variant="text"
          size="small"
          sx={(theme) => ({ color: theme.palette.common.white })}
          onClick={() => {
            window.location.href = `/fleet/devices/${data.serialNumber}`;
          }}
        >
          {t('details')}
        </Button>

        <Button
          variant="text"
          size="small"
          sx={(theme) => ({ color: theme.palette.common.white })}
          onClick={(e) => {
            copyToClipboard({
              text: toClipboardData,
              onSucces: () => {
                dispatchToast({
                  message: t('copyToClipboardSuccess'),
                  severity: 'success',
                  position: { x: e.clientX, y: e.clientY },
                });
              },
              onFail: () => {
                dispatchToast({
                  message: t('copyToClipboardError'),
                  severity: 'error',
                  position: { x: e.clientX, y: e.clientY },
                });
              },
            });
          }}
        >
          {t('copy')}
        </Button>
      </Box>
    </Box>
  );
};

export default DeviceTooltipContent;

export type DeviceTooltipContentProps = {
  name: string;
  model: string;
  serialNumber: string;
  errors: number;
  coordinates: { lat: number; lng: number };
  alarms: {
    [key: string]: number;
  };
};
