import { Paper, Typography } from '@mui/material';
import LoadingFeedback from './LoadingFeedback';
import { ACTION_STATUS } from './constants';
import ErrorFeedback from './ErrorFeedback';
import SuccessFeedback from './SuccessFeedback';
import { AddBoxOutlined, FilterCenterFocusOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { VacantSlotProps } from './typings';
import { grey } from '@mui/material/colors';
import SlotFooter from './SlotFooter';

export default function VacantSlot({
  index,
  handleClickEdit,
  isDeviceConnected,
  actionStatus,
  performedAction,
  slot,
}: VacantSlotProps) {
  const { t } = useTranslation();

  return (
    <Paper
      onClick={() => handleClickEdit()}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.grayShades[0],
        display: 'grid',
        gridTemplate: '1fr auto / 1fr',
        cursor: isDeviceConnected === true ? 'pointer' : 'default',
        borderRadius: 1,
        boxShadow: 1,
        position: 'relative',
        zIndex: 1,
        maxWidth: '600px',
        width: '100%',
      })}
    >
      <LoadingFeedback loading={actionStatus === ACTION_STATUS.Loading} />
      <ErrorFeedback hasErrors={actionStatus === ACTION_STATUS.Error} action={performedAction} />
      <SuccessFeedback success={actionStatus === ACTION_STATUS.Success} />
      <Paper
        elevation={0}
        variant="outlined"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          minHeight: '341.016px',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          borderRadius: 1,
          width: '100%',
        })}
      >
        <FilterCenterFocusOutlined sx={{ fontSize: '80px', color: grey[300] }} />
        <Typography variant="h6" color={grey[500]} fontWeight={800} sx={{ textTransform: 'uppercase' }}>
          {t('emptySlot')}
        </Typography>
      </Paper>
      <SlotFooter
        actions={[
          {
            icon: <AddBoxOutlined sx={{ mr: '0.2rem' }} />,
            label: t('add'),
            onClick: () => {},
          },
        ]}
        index={index}
        isDeviceConnected={isDeviceConnected}
        slot={slot}
      />
    </Paper>
  );
}
