import { useDispatch, useSelector } from 'react-redux';
import {
  BusinessAnalysisFiltersState,
  TimeRange,
  businessAnalysisSelectTransformedValue,
  setFilters,
} from '../../redux/slices/BusinessAnalysis/businessAnalysisFiltersSlice';
import StoreFilters from '../Shared/Filters/StoreFilters';
import Button from '@mui/material/Button/Button';
import { Box, Typography } from '@mui/material';
import CulliganFAB from '@/components/Shared/CulliganFAB';
import { StoreFilterConfig, StoreFilterOption } from '@/components/Shared/Filters/typings';
import StatisticsRow from '@/components/Shared/StatisticsRow';
import WaterDispensedCard from './WaterDispensedCard';
import TopFlavorsCard from './TopFlavorsCard';
import dayjs from 'dayjs';
import useTypedSelector from '@/hooks/useTypedSelector';
import WaterTypesCard from './WaterTypesCard';
import TopWaterFlavorMixCard from './TopWaterFlavorMixCard';
import PouringsCard from './PouringsCard';
import FiltersStatusCard from './FiltersStatusCard';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useLazyGetBusinessAnalysisFiltersQuery } from '@/redux/api/businessAnalysis/filtersApiSlice';
import useStatisticsData from './useStatisticsData';
import { DateRangeDefaultValue } from '@/shared/utils';
import {
  AggregatedValue,
  OverviewFiltersResponse,
  TimeSeriesValue,
  useLazyGetUsersQuery,
  UsersFilterRequest,
  UsersFilterResponse,
} from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import Header from '../Shared/Print/Header';
import { ExportCsv } from '@material-table/exporters';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Column } from '@material-table/core';
import PrintFilters from '../Shared/Filters/PrintFilters';
import PreferredWaterTypeAndFlavorCard from './PreferredWaterTypeAndFlavorCard';
import { fleetApiSlice } from '@/redux/api/fleet/fleetApiSlice';

type ApiState = {
  api: {
    queries: {
      [key in string]: {
        status?: string;
      };
    };
  };
};

function handleCsvExport(filteredData: OverviewFiltersResponse | undefined) {
  if (!filteredData) {
    return;
  }
  const uniqueKeys = new Map<string, Column<Partial<TimeSeriesValue & AggregatedValue>>>();
  for (const key in filteredData) {
    filteredData[key as keyof typeof filteredData]?.value.forEach((value) => {
      Object.keys(value).forEach((k) => {
        if (uniqueKeys.has(k)) {
          return;
        }
        uniqueKeys.set(k, {
          title: i18n.t(k),
          field: k,
          ...(k === 'day'
            ? {
                exportTransformer: (row) => row.day && dayjs(row.day).format('YYYY/MM/DD HH:mm:ss'),
              }
            : {}),
        });
      });
    });
  }
  const columns = [...uniqueKeys.values()];

  columns.unshift({ title: i18n.t('entity'), field: 'entity' });

  const data = Object.keys(filteredData).reduce<Partial<TimeSeriesValue & AggregatedValue & { entity: string }>[]>(
    (acc, k) => {
      return [
        ...acc,
        ...(filteredData[k as keyof typeof filteredData]?.value.map((v) => ({
          entity: k,
          ...v,
        })) || []),
      ];
    },
    []
  );

  ExportCsv<Partial<TimeSeriesValue & AggregatedValue>>(columns, data, 'Business Analysis');
}

export default function Overview() {
  const { t } = useTranslation();
  const { hasQueryParams, filteredData } = useStatisticsData();
  const dispatch = useDispatch();
  const businessAnalysisState = useTypedSelector(businessAnalysisSelectTransformedValue);
  const [trigger, { data, isError, isLoading, isFetching, isUninitialized }] = useLazyGetBusinessAnalysisFiltersQuery();
  const hasAlreadyQueriedUsers = useSelector((state) => {
    const queries = (state as ApiState).api.queries;
    const query = queries[Object.keys(queries).find((key) => key.includes('getUsers(')) || ''] || null;
    return query?.status;
  });

  const hasTopWaterFlavorMixData =
    filteredData && filteredData.waterFlavorMix && filteredData.waterFlavorMix.value.length > 0;
  const hasFilterStatusData = filteredData && filteredData.filtersStatus && filteredData.filtersStatus.value.length > 0;

  const customerDefaultValue = data?.data?.items?.customer.at(0) || null;
  const brandDefaultValue = data?.data?.items?.brand.find((brand) => brand.id === businessAnalysisState.brand);
  const typeDefaultValue = data?.data?.items?.type.find((type) => type.id === businessAnalysisState.type);
  const dateRangeFallbackValue = new DateRangeDefaultValue(
    dayjs().subtract(7, 'day').startOf('day'),
    dayjs().endOf('day')
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const dateRangeDefaultValue = businessAnalysisState.dateRange
    ? new DateRangeDefaultValue(businessAnalysisState.dateRange.start, businessAnalysisState.dateRange.end)
    : dateRangeFallbackValue;

  useLayoutEffect(() => {
    if (hasQueryParams || businessAnalysisState.dateRange) {
      return;
    }

    dispatch(
      setFilters({
        dateRange: dateRangeFallbackValue.toUnixAsString(),
      } as BusinessAnalysisFiltersState)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasAlreadyQueriedUsers && isUninitialized) {
      trigger({
        customer: businessAnalysisState.customer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAnalysisState.customer, isUninitialized]);

  const filterConfig: StoreFilterConfig[] | undefined = useMemo(() => {
    if (isError || isFetching || isLoading || !data) {
      return;
    }

    return [
      {
        id: 'brand',
        label: t('brand'),
        options: data.data?.items.brand.map((brand) => ({ optionId: brand.id, label: brand.name })) || [],
        kind: 'autocomplete',
        ...(businessAnalysisState.brand && brandDefaultValue
          ? {
              defaultValue: {
                optionId: brandDefaultValue.id,
                label: brandDefaultValue.name,
              },
            }
          : {}),
      },
      {
        id: 'type',
        label: t('productName'),
        options: data.data?.items.type.map((type) => ({ optionId: type.id, label: type.name })) ?? [],
        kind: 'autocomplete',
        ...(businessAnalysisState.type && typeDefaultValue
          ? {
              defaultValue: {
                optionId: typeDefaultValue.id,
                label: `${typeDefaultValue.name}`,
              },
            }
          : {}),
      },
      {
        id: 'customer',
        label: t('customer'),
        kind: 'asyncAutocomplete',
        ...(businessAnalysisState.customer && customerDefaultValue
          ? {
              defaultValue: {
                optionId: customerDefaultValue.id,
                label: `${customerDefaultValue.firstName} ${customerDefaultValue?.lastName}`,
              },
            }
          : {}),
        shouldFetch: (query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query),
        getInitialValue: async () => {
          if (!businessAnalysisState.customer) {
            return;
          }
          const result = await dispatch(
            fleetApiSlice.endpoints.getFleetCustomer.initiate({ customerId: businessAnalysisState.customer })
          );
          const _customer = result?.data?.data?.customer;

          return { label: `${_customer?.firstName} ${_customer?.lastName}`, optionId: _customer?.id || '' };
        },
        transformFn: (data: { items: UsersFilterResponse[] }) => {
          return data?.items?.map((item) => {
            const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
            const hasHomonym =
              toSearchForMatches?.length > 0 &&
              toSearchForMatches.findIndex(
                (user) =>
                  user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
                  user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
              ) !== -1;
            return { ...item, optionId: item?.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
          });
        },
        lazyQueryHook: useLazyGetUsersQuery,
        getQueryParam: (query) => ({ query } as UsersFilterRequest),
        debounceTime: 400,
        renderOption: (
          props,
          option: StoreFilterOption & { email?: string | undefined; hasHomonym?: boolean | undefined },
          state
        ) => {
          const matches = match(option.label, state.inputValue, { insideWords: true });
          const labelWithQueryHighlight = parse(option.label, matches);

          return (
            <li {...props} key={option?.optionId}>
              <Box alignItems="center">
                <Box sx={{ wordWrap: 'break-word' }}>
                  {labelWithQueryHighlight.map((part, index) => (
                    <Box
                      key={`${index}-${part.text}`}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  {option?.hasHomonym && (
                    <Typography variant="body2" color="text.secondary">
                      {option?.['email']}
                    </Typography>
                  )}
                </Box>
              </Box>
            </li>
          );
        },
      },
      {
        id: 'dateRange',
        label: t('dateRange'),
        kind: 'dateRangeWithFixedRanges',
        defaultValue: dateRangeDefaultValue.unix(),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isFetching, isLoading, data, businessAnalysisState, dateRangeDefaultValue]);

  const handleFiltersApplied = (filtersApplied: Map<string, string>) => {
    const mappedFilters: Partial<BusinessAnalysisFiltersState> = {};

    filtersApplied.forEach((value, key) => {
      if (key !== 'dateRange') {
        mappedFilters[key as keyof Omit<BusinessAnalysisFiltersState, 'dateRange'>] = value;
        return;
      }

      if (key === 'dateRange') {
        const dateRange = JSON.parse(value) as TimeRange<string>;
        mappedFilters[key as keyof Pick<BusinessAnalysisFiltersState, 'dateRange'>] = dateRange;
      }
    });

    dispatch(setFilters(mappedFilters));
  };

  const handleFiltersCleared = () => {
    dispatch(setFilters({ dateRange: dateRangeFallbackValue.toUnixAsString() }));
  };

  const dialogBody = (
    <Box>
      <Typography variant="h5" fontWeight={400}>
        {t('generateReportBusinessOverview')}
      </Typography>
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Button variant="outlined" onClick={() => window.print()} disabled={isLoading || isFetching}>
          {t('downloadPDF')}
        </Button>
        <Button variant="outlined" onClick={() => handleCsvExport(filteredData)} disabled={isLoading || isFetching}>
          {t('downloadCSV')}
        </Button>
      </Box>
    </Box>
  );

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: (_: any, _reason: any) => setOpenDialog(false),
    tabs: [
      {
        id: 'exportDialog',
        label: t('downloadTheReport'),
        body: dialogBody,
      },
    ],
    styles: {
      dialogContentContainer: {
        p: 5,
      },
    },
  });

  const handleDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Box>
      {dialog}
      <CulliganFAB label={t('export')} onClick={() => handleDialog()} />
      {!isError && !isLoading && !isFetching && data && (
        <StoreFilters
          sx={{ '@media print': { display: 'none' } }}
          filterConfigs={filterConfig as StoreFilterConfig[]}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}
      <Header />
      <Box
        sx={{
          marginTop: '100px',
        }}
      >
        <PrintFilters
          filtersState={businessAnalysisState}
          transformers={{
            brand: (value) => data?.data?.items.brand.find((item) => item.id === value)?.name || value,
            customer: (value) => data?.data?.items.customer.find((item) => item.id === value)?.firstName || value,
          }}
        />

        <StatisticsRow
          sx={(theme) => ({
            gridTemplate: 'auto / 1fr 1fr',
            [theme.breakpoints.down('lg')]: {
              gridTemplate: '1fr / 1fr',
            },
          })}
        >
          <WaterDispensedCard />
          <PouringsCard />
        </StatisticsRow>
        <StatisticsRow
          sx={(theme) => ({
            gridTemplate: 'auto / 3fr 2fr',
            [theme.breakpoints.down('lg')]: {
              gridTemplate: '1fr / 1fr',
            },
          })}
        >
          <WaterTypesCard />
          <TopFlavorsCard />
        </StatisticsRow>
        <StatisticsRow
          sx={(theme) => ({
            gridTemplate: 'auto / 2fr 3fr',
            [theme.breakpoints.down('lg')]: {
              gridTemplate: '1fr / 1fr',
            },
          })}
        >
          {hasTopWaterFlavorMixData && <TopWaterFlavorMixCard />}
          {hasFilterStatusData && <FiltersStatusCard />}
        </StatisticsRow>
        <StatisticsRow>
          <PreferredWaterTypeAndFlavorCard />
        </StatisticsRow>
      </Box>
    </Box>
  );
}
