import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteConsumableMutation,
  useGetConsumablesQuery,
  usePostConsumableMutation,
  usePutConsumableMutation,
  useLazyIsConsumableUniqueQuery,
} from '@/redux/api/system/consumablesApiSlice';
import { useTranslation } from 'react-i18next';
import { ConsumableReq, ConsumableRes } from './typings';
import useConsumablesColumns from './useConsumablesColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CONSUMABLE_KIND, ROUTES } from '@/shared/constants';
import { FieldsUndefined } from '@typings';
import useUniqueness from '@/hooks/useUniqueness';

export const ConsumablesTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConsumableRes>));

export default function ConsumablesPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetConsumablesQuery();
  const [postConsumableTrigger] = usePostConsumableMutation();
  const [putConsumableTrigger] = usePutConsumableMutation();
  const [deleteConsumableTrigger] = useDeleteConsumableMutation();
  const [isConsumableUnique] = useLazyIsConsumableUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<ConsumableRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isConsumableUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<ConsumableReq>({
    title: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'kind',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(CONSUMABLE_KIND).map((consumable) => ({ label: t(consumable), value: consumable })),
        },
      },
    ],
    getEditData: async (dialogId: string | boolean) =>
      data?.data?.items?.find((consumable: any) => consumable.id === dialogId),
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const submitData = { ...data };

      if (isEditing) {
        await putConsumableTrigger({ id: dialogId, body: data });
      } else {
        await postConsumableTrigger(submitData);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onChange',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('consumable'),
    message: (extra: any) => t('deleteMessage', { entity: t('consumable'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteConsumableTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useConsumablesColumns(openDialog, confirm);

  return (
    <>
      <ConsumablesTable
        title={t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('consumable')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
