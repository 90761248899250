import { ApiResult } from '@typings';
import { EventReq, EventRes } from '@/components/OneTools/Channels/Events/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/events`;

export const eventsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Events'] }).injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ApiResult<{ items: EventRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Events'],
    }),
    getEvent: builder.query<ApiResult<EventRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Events'],
    }),
    isEventUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
    postEvent: builder.mutation<ApiResult<EventRes>, EventReq>({
      query: (body: EventReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Events'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
        });
      },
    }),
    putEvent: builder.mutation<ApiResult<EventRes>, { id: string; body: EventReq }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Events'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
        });
      },
    }),
    patchEvent: builder.mutation<ApiResult<EventRes>, { id: string; body: Partial<EventReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Events'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
        });
      },
    }),
    deleteEvent: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['Events'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
        });
      },
    }),
  }),
});

export const {
  useLazyGetEventsQuery,
  useGetEventsQuery,
  useLazyGetEventQuery,
  useLazyIsEventUniqueQuery,
  usePostEventMutation,
  usePutEventMutation,
  usePatchEventMutation,
  useDeleteEventMutation,
} = eventsApiSlice;
