import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MarkerInfo } from './typings';
import createMarkerTooltip from './MarkerTooltip';

class MarkersManager {
  private map: google.maps.Map;
  private clusterer: MarkerClusterer;
  private markers: google.maps.Marker[];
  private bounds: google.maps.LatLngBounds;

  constructor(map: google.maps.Map, clusterer: MarkerClusterer) {
    this.map = map;
    this.clusterer = clusterer;
    this.markers = [];
    this.bounds = new google.maps.LatLngBounds();
  }

  addMarker(info: MarkerInfo) {
    const position = info.position.lat && info.position.lng ? (info.position as google.maps.LatLngLiteral) : null;
    const marker = new google.maps.Marker({
      map: this.map,
      position,
      title: info.title,
    });
    const mId = Math.random();
    marker.set(
      'infowindow',
      new google.maps.InfoWindow({
        content: `<div id="${mId}" />`,
      })
    );
    const map = this.map;
    marker.addListener('click', function (this: google.maps.Marker) {
      const iw = this.get('infowindow');
      iw.open(map, this);
      google.maps.event.addListener(iw, 'domready', () => createMarkerTooltip(`${mId}`, info));
      return () => google.maps.event.clearInstanceListeners(iw);
    });
    this.bounds.extend(marker.getPosition()!);
    this.markers.push(marker);
    return this;
  }

  updateClusterer() {
    this.markers.forEach((m) => this.clusterer.addMarker(m, true));
    return this;
  }

  clearAll() {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers.length = 0;
    this.bounds = new google.maps.LatLngBounds();
    if (this.clusterer) {
      this.clusterer.clearMarkers(true);
    }
    return this;
  }

  reloadMap() {
    this.map.fitBounds(this.bounds);

    if (this.markers.length === 1) {
      const zoomChangeBoundsListener = google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        if (this.map.getZoom()) {
          this.map.setZoom(13);
          google.maps.event.removeListener(zoomChangeBoundsListener);
        }
      });
    }

    google.maps.event.trigger(this.map, 'resize');

    return this;
  }

  preventCoordinatesCollision(_makers: MarkerInfo[]): MarkerInfo[] {
    const coordinates = new Set();
    const PLOT_COLLISION_OFFSET = 0.00005;
    const generateCoordinateWithRandomOffset = (lat: number, lng: number) => [
      (lat += (Math.random() - 0.5) * PLOT_COLLISION_OFFSET),
      (lng += (Math.random() - 0.5) * PLOT_COLLISION_OFFSET),
    ];
    const checkForCollision = (lat: number, lng: number): { lat: number; lng: number } => {
      if (coordinates.has(`${lat},${lng}`)) {
        const [latWithOffset, lngWithOffset] = generateCoordinateWithRandomOffset(lat, lng);
        return checkForCollision(latWithOffset, lngWithOffset);
      }
      coordinates.add(`${lat},${lng}`);
      return { lat, lng };
    };

    return _makers.flatMap((marker) => {
      let lat = marker.position.lat;
      let lng = marker.position.lng;

      if (!lat || !lng) {
        return [];
      }
      const coordinate = checkForCollision(lat, lng);
      return { ...marker, position: { lat: coordinate.lat, lng: coordinate.lng } };
    });
  }
}

export default MarkersManager;
