import React, { JSXElementConstructor } from 'react';
import { Box, Tooltip as MuiTooltip, Typography, SxProps, TypographyProps } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { RequireAtLeastOne } from '@typings';

interface TypographyWithTooltipProps {
  anchorText: string;
  tooltipText: string;
  iconSize?: number;
  sx?: SxProps;
  slots?: RequireAtLeastOne<{
    AnchorTextTypography: JSXElementConstructor<TypographyProps>;
  }>;
}

const TypographyWithTooltip = ({
  anchorText,
  tooltipText,
  iconSize = 20,
  sx,
  slots,
  ...rest
}: TypographyWithTooltipProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {slots?.AnchorTextTypography && <slots.AnchorTextTypography>{anchorText}</slots.AnchorTextTypography>}
      {!slots?.AnchorTextTypography && anchorText}
      <MuiTooltip
        title={<Typography sx={{ whiteSpace: 'pre-line' }}>{tooltipText}</Typography>}
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: 2,
              fontSize: '0.675rem',
              padding: '1rem',
              maxWidth: '15rem',
            },
          },
        }}
        {...rest}
      >
        <InfoRounded
          sx={{
            color: 'secondary.main',
            fontSize: iconSize,
            ml: '0.5rem',
            ...sx,
          }}
        />
      </MuiTooltip>
    </Box>
  );
};

export default TypographyWithTooltip;
