import useTelemetryColumns from './useTelemetryColumns';
import {
  useDeleteTelemetryMutation,
  useGetTelemetryEntriesQuery,
  useLazyGetTelemetryEntryQuery,
  usePutTelemetryMutation,
  usePostTelemetryMutation,
  useLazyIsTelemetryUniqueQuery,
} from '@/redux/api/system/telemetryApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { TelemetryReq, TelemetryRes } from './typings';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import SI_UNITS from './si_units.json';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FieldsUndefined } from '@typings';
import { ROLES, PRIMITIVES, TELEMETRY_SENDING_STYLE, SENDING_RULE, TELEMETRY_TYPE, ROUTES } from '@/shared/constants';
import { capitalize } from '@mui/material';
import { isValidNumber } from '@/shared/utils';
import useUniqueness from '@/hooks/useUniqueness';
import { UseFormReturn } from 'react-hook-form';
import { AutocompleteOption, ToggleFields } from '@/components/Shared/AddEditDialog/typings';

export const TelemetryTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<TelemetryRes>));

type AutocompleteOptionWithValues<TValues> = Omit<AutocompleteOption, 'value'> & { value: TValues[keyof TValues] };

type TelemetryForm = Omit<TelemetryReq, 'sendingStyle'> & {
  sendingStyle: AutocompleteOptionWithValues<typeof TELEMETRY_SENDING_STYLE>;
  primitive: AutocompleteOptionWithValues<typeof PRIMITIVES>;
};

const fieldsToHideByPrimitive = (primitive?: AutocompleteOptionWithValues<typeof PRIMITIVES>) => {
  if (!primitive) {
    return [
      'triageRangeMax',
      'triageRangeMin',
      'triageExpectedBooleanValue',
      'triageExpectedStringValue',
      'unitOfMeasure',
    ];
  }

  if (primitive.value === PRIMITIVES.BOOLEAN) {
    return ['triageRangeMax', 'triageRangeMin', 'triageExpectedStringValue', 'unitOfMeasure'];
  }

  if (primitive.value === PRIMITIVES.NUMBER) {
    return ['triageExpectedBooleanValue', 'triageExpectedStringValue'];
  }

  if (primitive.value === PRIMITIVES.STRING) {
    return ['triageRangeMax', 'triageRangeMin', 'triageExpectedBooleanValue', 'unitOfMeasure'];
  }

  return [];
};

const fieldsToHideBySendingStyle = (sendingStyle?: AutocompleteOptionWithValues<typeof TELEMETRY_SENDING_STYLE>) => {
  if (
    !sendingStyle ||
    sendingStyle.value === TELEMETRY_SENDING_STYLE.ALWAYS ||
    sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_CHANGE
  ) {
    return ['sendingRule', 'value'];
  }

  if (
    sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_DELTA ||
    sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_THRESHOLD
  ) {
    return [];
  }

  return [];
};

const handleHideFieldBySendingStyleAndPrimitive = (
  getValues: UseFormReturn['getValues'],
  toggleFields: ToggleFields
) => {
  const _getValues = getValues as unknown as UseFormReturn<TelemetryForm>['getValues'];
  const { sendingStyle, primitive } = _getValues();

  toggleFields(
    (name) => ![...fieldsToHideBySendingStyle(sendingStyle), ...fieldsToHideByPrimitive(primitive)]?.includes(name)
  );
  return;
};

export default function TelemetryPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetTelemetryEntriesQuery();

  const [getTelemetry] = useLazyGetTelemetryEntryQuery();
  const [postTelemetryTrigger] = usePostTelemetryMutation();
  const [putTelemetryTrigger] = usePutTelemetryMutation();
  const [deleteTelemetryTrigger] = useDeleteTelemetryMutation();
  const [isTelemetryUnique] = useLazyIsTelemetryUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<TelemetryRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isTelemetryUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<TelemetryReq>({
    title: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(TELEMETRY_TYPE).map((value) => ({ label: t(value), value })),
        },
      },
      {
        type: 'autocomplete',
        name: 'primitive',
        placeholder: t('primitive'),
        options: { required: t('fieldRequiredError') as string, deps: ['unitOfMeasure'] },
        selectConfig: {
          options: Object.values(PRIMITIVES).map((primitive) => ({
            label: t(primitive),
            value: primitive,
          })),
        },
        mutations: (_, __, toggleFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => {
                  handleHideFieldBySendingStyleAndPrimitive(getValues, toggleFields);
                },
              },
            },
          },
        }),
      },
      {
        type: 'autocomplete',
        name: 'unitOfMeasure',
        placeholder: t('unitOfMeasure'),
        options: {
          deps: ['primitive'],
          validate: (value, { primitive }) => {
            const isUnitOfMeasureRequired = primitive.value === PRIMITIVES.NUMBER;
            if (isUnitOfMeasureRequired) {
              return !value ? (t('fieldRequiredError') as string) : true;
            }
            return true;
          },
        },
        selectConfig: {
          options: SI_UNITS.map((unit) => ({ ...unit, label: `${unit.label} - ${unit.value}` })),
        },
      },
      {
        name: 'triageRangeMin',
        placeholder: t('triageRangeMin'),
        type: 'number',
        options: {
          required: true,
          deps: ['sendingStyle', 'primitive'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'triageRangeMax',
        placeholder: t('triageRangeMax'),
        type: 'number',
        options: {
          required: true,
          deps: ['sendingStyle', 'primitive'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'triageExpectedBooleanValue',
        placeholder: t('triageExpectedValue'),
        options: {
          required: true,
          deps: ['primitive'],
        },
        selectConfig: {
          options: [true, false].map((v) => ((value) => ({ label: capitalize(value), value }))(String(v))),
        },
      },
      {
        name: 'triageExpectedStringValue',
        placeholder: t('triageExpectedValue'),
        options: {
          required: true,
          deps: ['primitive'],
        },
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string, deps: ['value'] },
        selectConfig: {
          options: Object.values(TELEMETRY_SENDING_STYLE).map((value) => ({ label: t(value), value })),
        },
        mutations: (_, __, toggleFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => handleHideFieldBySendingStyleAndPrimitive(getValues, toggleFields),
              },
            },
          },
        }),
      },
      {
        type: 'autocomplete',
        name: 'sendingRule',
        placeholder: t('sendingRule'),
        selectConfig: {
          options: Object.values(SENDING_RULE).map((value) => ({ label: t(value), value })),
        },
        options: {
          required: true,
          deps: ['sendingStyle'],
          validate: (value, formValues) => {
            if (
              formValues.sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_THRESHOLD ||
              formValues.sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_DELTA
            ) {
              return value && value !== '' ? true : (t('fieldRequiredError') as string);
            }
            return true;
          },
        },
      },
      {
        name: 'value',
        placeholder: t('value'),
        type: 'number',
        options: {
          required: true,
          deps: ['sendingStyle'],
          validate: (value, formValues) => {
            if (
              [TELEMETRY_SENDING_STYLE.ON_THRESHOLD, TELEMETRY_SENDING_STYLE.ON_DELTA].some(
                (sendingStyle) => sendingStyle === formValues.sendingStyle.value
              )
            ) {
              return !isValidNumber(value) ? (t('fieldNumberError') as string) : true;
            }
            return true;
          },
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        type: 'checkbox',
        name: 'alertIntegration',
        placeholder: t('alertIntegration'),
        initialValue: false,
        options: {
          deps: ['triageRangeMin', 'triageRangeMax', 'triageExpectedBooleanValue', 'triageExpectedStringValue'],
          validate: (value, formValues) => {
            const requiredDeps = [
              'triageRangeMin',
              'triageRangeMax',
              'triageExpectedBooleanValue',
              'triageExpectedStringValue',
            ];
            const isDepValid = requiredDeps.some(
              (dep) => formValues[dep] !== undefined && formValues[dep] !== null && formValues[dep] !== ''
            );
            return isDepValid ? true : (t('fieldRequiredError') as string);
          },
        },
      },
      {
        name: 'tags',
        placeholder: t('tags'),
        options: {
          required: false,
        },
        helperText: t('commaSeparatedValuesHelperText', { subject: t('tags') }) as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const telemetry: any = await getTelemetry(dialogId as string);

      return { ...telemetry.data?.data, tags: telemetry.data?.data.tags?.join(', ') };
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const triageFields = [
        (data as any).triageRangeMin,
        (data as any).triageRangeMax,
        (data as any).triageExpectedBooleanValue,
        (data as any).triageExpectedStringValue,
      ];

      const hasTriageFields = triageFields.some((field) => field !== undefined && field !== null && field !== '');

      if (data.alertIntegration && hasTriageFields) {
        data.alertIntegration = true;
      } else {
        data.alertIntegration = false;
      }

      if (data.primitive === PRIMITIVES.BOOLEAN) {
        data.triageExpectedValue = data.triageExpectedBooleanValue;
        delete data.triageExpectedBooleanValue;
      }

      if (data.primitive === PRIMITIVES.STRING) {
        data.triageExpectedValue = data.triageExpectedStringValue;
        delete data.triageExpectedStringValue;
      }

      if (data.tags) {
        data.tags = (data.tags as unknown as string).split(', ');
      }
      if (isEditing) {
        const id = dialogId;
        await putTelemetryTrigger({ id, body: data });
      } else {
        await postTelemetryTrigger(data);
      }
    },
    mutations: (_, __, toggleFields) => ({
      onMount: {
        impacts: {
          form: {
            handler: ({ getValues }) => {
              handleHideFieldBySendingStyleAndPrimitive(getValues, toggleFields);
            },
          },
        },
      },
    }),
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
    message: (extra: any) =>
      t('deleteMessage', { entity: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteTelemetryTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useTelemetryColumns(openDialog, confirm);

  return (
    <>
      <TelemetryTable
        title={t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment)}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
