import useEventsColumns from './useEventsColumns';
import {
  useDeleteEventMutation,
  useGetEventsQuery,
  useLazyGetEventQuery,
  usePutEventMutation,
  usePostEventMutation,
  useLazyIsEventUniqueQuery,
} from '@/redux/api/system/eventsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { EventRes, EventReq } from './typings';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FieldsUndefined } from '@/shared/typings';
import { isValidJSON } from '@/shared/utils';
import { ROLES, ROUTES, SENDING_STYLE } from '@/shared/constants';
import useUniqueness from '@/hooks/useUniqueness';

export const EventsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<EventRes>));

export default function EventsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetEventsQuery();
  const [getEvent] = useLazyGetEventQuery();
  const [postEventTrigger] = usePostEventMutation();
  const [putEventTrigger] = usePutEventMutation();
  const [deleteEventTrigger] = useDeleteEventMutation();
  const [isEventUnique] = useLazyIsEventUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<EventRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isEventUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<EventReq>({
    title: t('event'),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SENDING_STYLE).map((sendingStyle) => ({
            label: t(sendingStyle),
            value: sendingStyle,
          })),
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        type: 'checkbox',
        name: 'parameters',
        placeholder: t('parameters'),
        initialValue: false,
        mutations: (showFields, hideFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => {
                  const { parameters } = getValues();

                  parameters && showFields('payloadExample');
                  !parameters && hideFields('payloadExample');
                },
              },
            },
          },
        }),
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: false,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (formValues.parameters) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const event: any = await getEvent(dialogId as string);
      return event.data?.data;
    },
    mutations: (showFields, hideFields) => ({
      onMount: {
        impacts: {
          form: {
            handler: ({ getValues }) => {
              const { parameters } = getValues();

              parameters && showFields('payloadExample');
              !parameters && hideFields('payloadExample');
            },
          },
        },
      },
    }),
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      if (isEditing) {
        const id = dialogId;
        await putEventTrigger({ id, body: data });
      } else {
        await postEventTrigger(data);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onChange',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('event'),
    message: (extra: any) => t('deleteMessage', { entity: t('event'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteEventTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useEventsColumns(openDialog, confirm);

  return (
    <>
      <EventsTable
        title={t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('event')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
